@layer components {

    .banner{

    }

    .banner--visible{

    }

    .banner--active{

    }

    .banner__content{

    }

    .banner--cookie{
        display: none;
        position: fixed;
        z-index: var(--z-index-banner);
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: white;
        color: var(--color-grey2);
        font-size: 13px;
        border-top: 1px solid;
        border-color: color-mix(in srgb, black, transparent 95%);
        &.banner--visible{
            display: block;
        }
        & > .page__wrapper{
            padding-top: 12px;
            padding-bottom: 12px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        & .banner__content{
            max-width: 1100px;
        }
        & .btn--action{
            color: var(--color-grey2);
            &:hover{
                color: var(--color-primary);
            }
        }
    }

    .banner--self-employed{
        position: fixed;
        z-index: var(--z-index-banner);
        right: 5%;
        top: 85px;
        width: 100%;
        max-width: 90%;
        transform: translateX(100vw);
        transition: transform 1s;
        box-shadow: var(--shadow-medium);
        border-radius: 5px;
        background-color: var(--color-light2);
        &.banner--visible{
            transform: translateX(0);
        }
        & > .page__wrapper{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 15px;
        }
        & .banner__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        & h2{
            display: inline-block;
            margin-bottom: 15px;
            max-width: calc(100% - 30px);
        }
        & .btn{
            margin-bottom: 15px;
        }
        & .img{
            display: block;
            margin-bottom: -15px;
            width: 163px;
            height: 250px;
            flex-shrink: 0;
        }
        & .btn--action{
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
        }
        @media (min-width: theme(--breakpoint-sm) ) {
            right: 15px;
            max-width: 450px;
            & .banner__content{
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
            & h2{
                max-width: 100%;
                margin-bottom: 30px;
            }
        }
    }

    .banner--your-business{
        display: none;
        position: relative;
        margin-bottom: 15px;
        margin-top: -15px;
        z-index: var(--z-index-banner);
        &.banner--visible{
            display: block;
        }
        & .banner__content{
            background: linear-gradient(135deg, #83a1c6 0%,#6687b2 100%);
            color: white;
            padding: 15px 40px 15px 20px;
            border-radius: 4px;
            overflow: hidden;
            & p > a{
                color: currentColor !important;
                text-decoration: underline;
            }
            & .btn{
                border: none;
                background-color: white;
                color: black;
                &:hover,
                &:focus,
                &:active{
                    background-color: color-mix(in srgb, white 70%, transparent 30%);
                }
            }
        }
        & .btn--action{
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            color: white;
            &:hover,
            &:focus,
            &:active{
                color: color-mix(in srgb, white 70%, transparent 30%);
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .banner__content{
                & p{
                    max-width: 80%;
                }
            }
        }
    }

    .banner--registration-paused{
        width: 100%;
        background-color: var(--color-red);
        font-size: 18px;
        font-weight: var(--fw-medium);
        text-align: center;
        &.banner--visible{
            display: block;
        }
        & .page__wrapper{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .banner--law-docs-refuced,
    .banner--user-is-freezed,
    .banner--not-authorized-customer{
        width: 100%;
        margin-bottom: 15px;
        & .banner__content{
            text-align: left;
            padding: 20px;
            background-color: var(--color-alert-danger);
            border-radius: var(--border-rad-lg);
            padding-left: var(--page-gap);
            padding-right: var(--page-gap);
        }
        & + .wrapper--subheader{
            margin-top: 15px;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & .banner__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            & .text{
                margin-bottom: 0;
                margin-right: 20px;
            }
        }
    }

}
