@layer components {

    .list{

    }

    .list__item{

    }

    .list__item--active{

    }

    .list--inside{
        padding-left: 0;
        list-style-position: inside;
    }

    .list--info{
        --item-padding: 26px;
        list-style: none;
        padding-left: 0;
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        & .list__item{
            width: 100%;
            padding-top: var(--item-padding);
            padding-bottom: var(--item-padding);
            position: relative;
            border-bottom: 1px solid;
            border-color: var(--color-light2);
            &:first-of-type{
                border-top: 1px solid;
                border-color: var(--color-light2);
            }
            & > *{
                position: relative;
                z-index: 2;
            }
        }
        & .icon__wrapper{
            color: var(--color-light4);
            position: absolute;
            z-index: 1;
            top: var(--item-padding);
            right: 0;
        }
        & .info__value{
            font-size: 24px;
            line-height: 1.16;
            margin-bottom: 8px;
            color: black;
        }
        @media (min-width: theme(--breakpoint-sm) ) {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            grid-gap: 2px;
            & .icon__wrapper{
                right: var(--item-padding);
            }
            & .list__item{
                border: 1px solid;
                border-color: var(--color-light2);
                padding-left: var(--item-padding);
                padding-right: var(--item-padding);
            }
        }
    }

    .list--unstyled{
        list-style: none;
        padding-left: 0;
        & .list__item{
            &:not(:last-of-type){
                margin-bottom: 15px;
            }
        }
    }

    .list--mdash{
        list-style: none;
        padding-left: 20px;
        & .list__item{
            &::before{
                content: '— ';
                position: relative;
                left: -20px;
                margin-right: -1rem;
            }
            &:not(:last-of-type){
                margin-bottom: 10px;
            }
        }
    }

    .list--constructor-elements{
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 24px;
        & .list__item{
            padding: 24px;
            padding-right: 145px;
            width: 100%;
            background-color: white;
            border-radius: calc( 2*var(--border-rad-lg) );
            box-shadow: var(--shadow-small);
            position: relative;
            min-height: 186px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            & > *{
                position: relative;
                z-index: 1;
            }
            & .icon__wrapper{
                position: absolute;
                z-index: 0;
                top: 50%;
                right: 24px;
                transform: translateY(-50%);
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            grid-template-columns: 1fr 1fr;
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            grid-template-columns: 1fr 1fr 1fr;
            & .list__item{
                padding-right: 100px;
            }
            & .icon__wrapper{
                max-width: 64px;
            }
        }
    }

    .list--tags{
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        & .list__item{
            padding: 2px 8px;
            background-color: var(--color-grey7);
            border: 1px solid;
            border-color: var(--color-grey6);
            border-radius: var(--border-rad-lg);
            color: var(--color-grey1);
            font-size: 13px;
            margin-top: 2px;
            margin-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: color var(--anim-dur),
                        background-color var(--anim-dur),
                        border-color var(--anim-dur);
            &:not(:last-of-type){
                margin-right: 8px;
            }
        }
        &.list--tags-clickable{
            & .list__item{
                cursor: pointer;
                &:hover{
                    color: var(--color-primary);
                    background-color: var(--color-light1);
                    border-color: var(--color-sprout);
                }
            }
        }
    }

    .list--buttons{
        list-style: none;
        padding-left: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        & .btn{
            min-width: 32px;
            min-height: 32px;
            padding: 0;
            border-color: var(--color-grey4);
        }
        & > .list__item{
            margin-top: 4px;
            margin-bottom: 4px;
            &:not(:last-of-type){
                margin-right: 8px;
            }
        }
    }
}
