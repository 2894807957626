@layer components {

    .modal{
        display: none;
        position: fixed;
        z-index: var(--z-index-modal);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &[aria-hidden='true'] {
            display: none;
        }
    }

    .modal__overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: color-mix(in srgb, var(--color-grey1) 90%, transparent 10%);
        backdrop-filter: blur(8px);
        @media (min-width: theme(--breakpoint-md) ) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: var(--page-gap);
        }
    }

    .modal__container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 245px;
        & > .wrapper{
            flex-grow: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            background-color: var(--color-light1);
        }
        @media (min-width: theme(--breakpoint-md) ) {
            max-width: 600px;
            height: auto;
            max-height: calc(100vh - 2*var(--page-gap) );
            & > .wrapper{
                border-radius: calc( 2*var(--border-rad-lg) );
            }
        }
    }

    .modal__header{
        position: relative;
        padding-top: var(--modal-py-sm);
        padding-bottom: var(--modal-py-sm);
        padding-left: var(--modal-px-sm);
        padding-right: calc( var(--modal-px-sm) + var(--modal-close-width) - (var(--modal-close-width) - 16px)/2 );
        font-size: 18px;
        min-height: var(--modal-close-width);
        background-color: var(--color-light1);
        box-shadow: var(--shadow-modal-header);
        flex-shrink: 0;
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6{
            margin-top: 0;
            margin-bottom: 0;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            box-shadow: none;
            background-color: transparent;
            font-size: 28px;
            padding-top: 0;
            padding-bottom: calc( var(--modal-py-md)/2 );
            padding-left: 0;
            padding-right: var(--modal-close-width);
            color: white;
        }
    }

    .modal__main{
        flex-grow: 1;
        padding-left: var(--modal-px-sm);
        padding-right: var(--modal-px-sm);
        margin-top: var(--modal-py-sm);
        margin-bottom: var(--modal-py-sm);
        margin-right: 8px;
        overflow-y: auto;
        @media (min-width: theme(--breakpoint-md) ) {
            padding-left: var(--modal-px-md);
            padding-right: var(--modal-px-md);
            margin-top: var(--modal-py-md);
            margin-bottom: var(--modal-py-md);
            @mixin ieOnly {
                max-height: 70vh;
            }
        }
    }


    .modal__footer{
        padding-top: var(--modal-py-sm);
        padding-bottom: var(--modal-py-sm);
        padding-left: var(--modal-px-sm);
        padding-right: var(--modal-px-sm);
        box-shadow: var(--shadow-modal-footer);
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        @media (min-width: theme(--breakpoint-md) ) {
            box-shadow: none;
            padding-top: 0;
            padding-bottom: var(--modal-py-md);
            padding-left: var(--modal-px-md);
            padding-right: var(--modal-px-md);
        }
    }

    .modal__close{
        position: absolute;
        z-index: 1;
        top: calc( var(--modal-py-sm) + 0.6em);
        right: calc( var(--modal-px-sm) - (var(--modal-close-width) - 16px)/2 );
        color: var(--color-grey4);
        transform: translateY(-50%);
        & > .btn--action{
            color: var(--color-grey4);
            &:not(:disabled){
                &:hover,
                &:focus{
                    color: var(--color-primary);
                }
                &:active{
                    color: var(--color-primary2);
                }
            }
        }
        & .icon__vue{
            width: 20px;
            height: 20px;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            top: 0.6em;
            right: 0;
            & .icon__vue{
                width: 24px;
                height: 24px;
            }
        }
    }

    .modal--visible{
        display: block;
    }

    .modal--sm{
        @media (min-width: theme(--breakpoint-md) ) {
            & .modal__container{
                max-width: 400px;
            }
            & .modal__header{
                font-size: 24px;
            }
        }
    }

    .modal--lg{
        @media (min-width: theme(--breakpoint-md) ) {
            & .modal__container{
                max-width: 830px;
            }
            & .modal__header{
                font-size: 32px;
            }
        }
    }

    .modal--xl{
        @media (min-width: theme(--breakpoint-md) ) {
            & .modal__container{
                max-width: 1440px;
            }
            & .modal__header{
                font-size: 32px;
            }
        }
    }

    .modal--fullscreen{
        @media (min-width: theme(--breakpoint-md) ) {
            & .modal__container{
                min-height: 80vh;
            }
        }
    }

    .modal--close-locked{
        & .modal__overlay{
            padding: 0;
        }
        & .modal__container {
            max-width: 100vw;
            max-height: 100vh;
            min-height: 100%;
            & > .wrapper {
                border-radius: 0;
            }
        }
        .modal__header {
            box-shadow: var(--shadow-modal-header);
            background-color: var(--color-light1);
            padding-top: var(--modal-py-sm);
            padding-bottom: 16px;
            padding-left: var(--modal-px-sm);
            padding-right: var(--modal-px-sm);
            color: black;
        }
    }

    .modal--image-fullscreen{
        & .modal__container{
            width: auto;
            max-width: 100vw;
            border-radius: 0;
            & > .wrapper{
                border-radius: 0;
                background-color: transparent;
            }
        }
        & .modal__main{
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
    }

}
