/* START redefine scss*/
label{
    display: inline-block;
    margin-bottom: 0.5rem;
}

th {
    text-align: inherit;
}

ol, ul, menu {
    list-style: initial;
}

ul{
    padding-left: 40px; /* в /docs/api списки слетели  */
}

p{
    margin-bottom: 1rem;
}
/* END redefine scss*/


/* START common scss*/
*{
	box-sizing: border-box;
	max-width: 100%;
	word-break: break-word;
	&:matches(:active,:focus){

	}
	&:visited{

	}
	&:focus{

	}
	&:focus-visible{
		outline: none;
	}
}

html,
body{
	width: 100%;
	min-height: 100%;
	margin:  0;
	padding: 0;
	font-family: var(--font-sans);
	color: black;
	font-weight: var(--fw-normal);
	line-height: 1.3;
	font-size: 16px;
}

a{
	color: currentcolor;
	transition: color calc(0.5*var(--anim-dur));
	&:hover,
	&:active{
		color: var(--color-primary);
	}
}

img{
	max-width: 100%;
}

@supports(object-fit: contain){
	img{
		object-fit: contain;
	}
}

.p-14{
	font-size: 14px;
}

.p-15{
	font-size: 15px;
}

.scroll__measure{
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}

a[href^='tel:']{

}

a[href^='mailto:']{

}

.ql-picker-options{
	max-width: none;
}

.i18n--loading{
	color: transparent !important;
	& * {
		color: transparent !important;
	}
}

/* END common scss*/

