fieldset[disabled] .multiselect {
	pointer-events: none;
}

.multiselect__spinner {
	position: absolute;
	right: 1px;
    top: 50%;
    transform: translateY(-50%);
	width: 48px;
	height: 35px;
	background-color: transparent;
	display: block;
}

.multiselect__spinner{
	&::before,
	&::after{
		position: absolute;
		content: "";
		top: 50%;
		left: 50%;
		margin: -8px 0 0 -8px;
		width: 16px;
		height: 16px;
		border-radius: 100%;
		border-color: var(--color-primary-hover) transparent transparent;
		border-style: solid;
		border-width: 2px;
		box-shadow: 0 0 0 1px transparent;
	}
	&::before{
		animation: multiselect-spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
		animation-iteration-count: infinite;
	}
	&::after{
		animation: multiselect-spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
		animation-iteration-count: infinite;
	}
}


.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
	transition: opacity 0.4s ease-in-out;
	opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
	opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
	font-family: inherit;
	font-size: 15px;
	font-weight: var(--fw-normal);
	touch-action: manipulation;
}

.multiselect {
	box-sizing: content-box;
	display: block;
	position: relative;
	width: 100%;
	min-height: 40px;
	text-align: left;
	color: var(--color-grey1);
	& * {
		box-sizing: border-box;
	}
	&:not(.multiselect--disabled){
		&:hover{
			& .multiselect__tags{
                box-shadow: var(--shadow-btn-hover);
			}
		}
		&:focus{
			& .multiselect__tags{
				border-color: var(--color-primary-hover);
				box-shadow: var(--shadow-btn-hover),
							0 0 0 1px var(--color-primary-hover);
			}
		}
	}
	&:focus {
		outline: none;
	}
	&.control--sm{
		min-height: 32px;
		& .multiselect__select{
			width: 32px;
			height: 32px;
		}
		& .multiselect__tags{
			min-height: 32px;
			padding: 2px 32px 2px 12px;
		}
	}
	&.multiselect--search{
		& .multiselect__select {
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%238E8D8D'/%3E%3Cpath d='M12 14.4142L13.4142 13L19.1507 18.7365L17.7365 20.1507L12 14.4142Z' fill='%238E8D8D'/%3E%3C/svg%3E%0A");
			background-size: 20px 21px;
			background-repeat: no-repeat;
		}
		&.multiselect--disabled {
			& .multiselect__select {
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%23BBBBBB'/%3E%3Cpath d='M12 14.4142L13.4142 13L19.1507 18.7365L17.7365 20.1507L12 14.4142Z' fill='%23BBBBBB'/%3E%3C/svg%3E%0A");
			}
		}
		&.multiselect--active{
			& .multiselect__select {
				transform: translateY(-50%);
			}
		}
	}
	&.multiselect--compact{
		.multiselect__content-wrapper{
			max-width: 300px;
			width: auto;
			width: var(--content-width);
		}
	}
	&.control--invalid{
		& .multiselect__tags{
			border-color: var(--color-red) !important;
			box-shadow: var(--color-red),
						0 0 0 1px var(--color-red) !important;
		}
	}
}

.multiselect--disabled {
    color: var(--color-grey4);
    pointer-events: none;
    & .multiselect__tags{
        background-color: var(--color-light1);
    }
    & .multiselect__placeholder {
        color: var(--color-grey4);
    }
    & .multiselect__select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8'%3E%3Cpath fill='%23BBBBBB' fill-rule='evenodd' d='M10.59.59L6 5.17 1.41.59 0 2l6 6 6-6L10.59.59z' clip-rule='evenodd'/%3E%3C/svg%3E");
    }
}

.multiselect--active {
	z-index: 12;
    & .multiselect__tags{
        border-color: var(--color-primary-hover);
        box-shadow: var(--shadow-btn-hover),
                    0 0 0 1px var(--color-primary-hover) !important;
    }
	& .multiselect__select {
		transform: translateY(-50%) rotateZ(180deg);
	}
}

.multiselect__input,
.multiselect__single {
	position: relative;
	display: inline-block;
	min-height: 20px;
	line-height: 20px;
	border: none;
	border-radius: 0;
	background-color: transparent;
	padding: 0;
	width: 100%;
	transition: border 0.1s ease;
	box-sizing: border-box;
	margin-bottom: 0;
	vertical-align: top;
	&::placeholder {
		color: var(--color-grey3);
		opacity: 1;
	}
	&:focus{
		outline: none;
	}
}

.multiselect__tag{
	& ~ .multiselect__input,
	& ~ .multiselect__single{
		width: auto;
	}
}

.multiselect__single {
	padding-left: 0;
	margin-bottom: 0;
}

.multiselect__tags-wrap {
	display: inline-flex;
	flex-wrap: wrap;
}

.multiselect__tags {
	min-height: 40px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
    padding: 9px 40px 9px 12px;
	border-radius: var(--border-rad-lg);
	border: 1px solid;
	border-color: var(--color-light3);
	background-color: white;
	font-size: 15px;
	box-shadow: var(--shadow-initial);
	transition: border-color .15s ease-in-out,
				box-shadow .15s ease-in-out;
}

.multiselect__tag {
	position: relative;
	display: inline-block;
	padding: 3px 30px 3px 10px;
	border-radius: var(--border-rad-lg);
	margin-right: 4px;
	font-size: 14px;
	color: var(--color-grey1);
	line-height: 1;
	background-color: var(--color-grey7);
	border: 1px solid;
	border-color: var(--color-grey6);
	margin-top: 2px;
	margin-bottom: 2px;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
}

.multiselect__tag-icon {
	cursor: pointer;
	margin-left: 7px;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	font-weight: 700;
	font-style: initial;
	width: 22px;
	text-align: center;
	line-height: 1;
	transition: all 0.2s ease;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	&::after {
		content: "\00D7";
		color: color-mix(in srgb, var(--color-grey1) 80%, transparent 20%);;
		font-size: 15px;
		vertical-align: baseline;
	}
	&:focus,
	&:hover {
		background-color: white;
		&::after {
			color: var(--color-grey1);
		}
	}
}

.multiselect__current {
	line-height: 15px;
	min-height: 40px;
	box-sizing: border-box;
	display: block;
	overflow: hidden;
	padding: 9px 12px 0;
	padding-right: 30px;
	white-space: nowrap;
	margin: 0;
	text-decoration: none;
	border-radius: 5px;
	border: 1px solid var(--color-light2);
	cursor: pointer;
}

.multiselect__select {
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	text-decoration: none;
	cursor: pointer;
	transition: transform 0.2s ease;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8'%3E%3Cpath fill='%2361605F' fill-rule='evenodd' d='M10.59.59L6 5.17 1.41.59 0 2l6 6 6-6L10.59.59z' clip-rule='evenodd'/%3E%3C/svg%3E");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 12px 7px;
}


.multiselect__placeholder {
	color: var(--color-grey4);
	display: inline-block;
	margin-bottom: 0;
	padding-top: 0;
}

.multiselect--active .multiselect__placeholder {
	display: none;
}

.multiselect__content-wrapper {
	position: absolute;
	display: block;
	background-color: white;
	width: auto;
	max-width: none;
	min-width: 100%;
	max-height: 240px !important; /*ignore style property*/
    overflow: auto;
    box-shadow: var(--shadow-btn-hover);
    border-radius: var(--border-rad-lg);
    z-index: 5;
    margin-top: 4px;
	-webkit-overflow-scrolling: touch;
	@mixin scrollThin;
}

.multiselect__content {
	list-style: none;
	display: inline-block;
	padding: 0;
	margin: 8px 0;
	min-width: 100%;
	vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    margin-bottom: 4px;
}

.multiselect__content::webkit-scrollbar {
	display: none;
}

.multiselect__element {
	display: block;
}

.multiselect__option {
    display: block;
    color: var(--color-grey2);
	padding: 12px 12px;
	min-height: 40px;
	line-height: 1.4;
	text-decoration: none;
	text-transform: none;
	position: relative;
	cursor: pointer;
	/*white-space: nowrap;*/
	&:hover{
        color: var(--color-grey1);
		background-color: var(--color-light1);
	}
}

.multiselect__option--highlight {
	outline: none;
}

.multiselect__option--selected {
    color: var(--color-primary);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2375B94E' d='M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z'/%3E%3C/svg%3E%0A");
    background-size: 24px;
    background-position: calc(100% - 12px) center;
    background-repeat: no-repeat;
	&.multiselect__option--highlight {
        color: var(--color-primary);
	}
	& > span{
		max-width: calc(100% - 28px);
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.multiselect--disabled .multiselect__current{
	background-color: var(--color-light4);
}

.multiselect__option--disabled {
    color: var(--color-grey4);
	background-color: var(--color-light2) !important;
	cursor: text;
	pointer-events: none;
}

.multiselect__option--group {
	background-color: var(--color-light1);
	color: var(--color-primary);
}

.multiselect__option--group.multiselect__option--highlight {
	background-color: var(--color-light2);
	color: var(--color-primary);
	&::after {
		background-color: var(--color-light2);
	}
}

.multiselect__option--disabled.multiselect__option--highlight {
	background-color: var(--color-light2);
}

.multiselect__option--group-selected.multiselect__option--highlight {
	background-color: var(--color-red);
	color: white;
	&::after {
		background-color: var(--color-red);
		content: attr(data-deselect);
		color: white;
	}
}

.multiselect-enter-active,
.multiselect-leave-active {
	transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
	opacity: 0;
}

.multiselect__strong {
	margin-bottom: 8px;
	line-height: 20px;
	display: inline-block;
	vertical-align: top;
}

*[dir="rtl"]{
	& .multiselect {
		text-align: right;
	}
	& .multiselect__select {
		right: auto;
		left: 0px;
	}
	& .multiselect__tags {
		padding: 9px 9px 0px 40px;
	}
	& .multiselect__content {
		text-align: right;
	}
	& .multiselect__option:after {
		right: auto;
		left: 0;
	}
	& .multiselect__clear {
		right: auto;
		left: 12px;
	}
	& .multiselect__spinner {
		right: auto;
		left: 1px;
	}
}


@keyframes multiselect-spinning {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(2turn);
	}
}

.multiselect--tags{
	& .multiselect__content-wrapper,
	& .multiselect__select{
		display: none !important;
	}
	& .multiselect__input{
		max-width: 150px;
	}
}

@supports(display: contents){
	.multiselect--tags{
		& .multiselect__tags-wrap{
			display: contents;
		}
		& .multiselect__tags{
			flex-wrap: wrap;
		}
	}
}

.multiselect--list-tags{
	&.multiselect--disabled {
        & .multiselect__select,
        & .multiselect__placeholder,
        & .multiselect__tag-icon{
            display: none;
        }
        & .multiselect__tag{
            padding-right: 10px;
        }
        & .multiselect__tags{
            padding: 0;
            background-color: transparent;
            border: none;
        }
    }
}
