@layer components {

    .blog{
        width: 100%;
        display: flex;
        flex-direction: column;
        @media (min-width: theme(--breakpoint-lg) ) {
            flex-direction: row;
        }
    }

    .blog--inverse{
        & .blog__content{
            order: 2;
        }
        & .blog__aside{
            order: 1;
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .blog__content{
                order: 1;
            }
            & .blog__aside{
                order: 2;
            }
        }
    }

    .blog--with-aside{
        & .blog__content{
            @media (min-width: theme(--breakpoint-lg) ) {
                max-width: calc(100% - 250px - 30px);
            }
        }
    }

    .blog__content{
        width: 100%;
        margin-bottom: 30px;
        & a{
            &:not(.btn):not(.message){
                color: var(--color-primary);
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            margin-right: 30px;
            margin-bottom: 10px;
        }
    }

    .blog__aside{
        width: 100%;
        @media (min-width: theme(--breakpoint-lg) ) {
            flex-shrink: 0;
            max-width: 250px;
        }
    }
}
