@layer components {

    .section{

    }

    .section__title{

    }

    .section__subtitle{

    }

    .section--front{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .section__bg {
            --color-malachite: #1AE66F;
            --color-emerald: #40BF75;
            display: flex;
            align-items: stretch;
            justify-content: center;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: linear-gradient(110.17deg, color-mix(in srgb, var(--color-malachite) 6%, transparent 94%) 2.05%, color-mix(in srgb, var(--color-emerald) 3%, transparent 97%), color-mix(in srgb, var(--color-malachite) 6%, transparent 94%) 99.03%),
                linear-gradient(to bottom, black 0%, black 100%);
            & .icon__wrapper {
                max-width: none;
                display: flex;
                align-items: stretch;
                justify-content: center;
            }

            & .icon__vue {
                filter: blur(2px);
                width: auto;
                height: auto;
                max-width: none;
            }
        }
        @media (min-width: theme(--breakpoint-sm) ) {

        }
        @media (min-width: theme(--breakpoint-md) ) {

        }
        @media (min-width: theme(--breakpoint-xl) ) {

        }
    }

    @supports(mix-blend-mode: soft-light) {
        .section--front{
            & .section__bg {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: color-mix(in srgb, white 20%, transparent 80%);
                    mix-blend-mode: soft-light;
                }
            }
        }
    }

    .section--subheader{
        background-color: white;
        box-shadow: var(--shadow-header);
        padding-top: 32px;
        margin-bottom: 32px;
    }
}
