@layer components {

    .icon{

    }

    .icon__wrapper{

    }

    .icon__vue{
        display: block;
        transition: color .15s ease-in-out,
                    fill .15s ease-in-out;
    }

    .icon--star-transparent{
        .icon__vue > *{
            fill: transparent;
        }
    }
}

