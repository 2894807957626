@layer components {

    .snippet{

    }

    .snippet__header{

    }

    .snippet__body{

    }

    .snippet__value{

    }

    .snippet__unit{

    }

    .snippet__title{

    }

    .snippet--project{
        font-size: 15px;
        padding: 14px;
        border-bottom: 1px solid;
        border-color: var(--color-light2);
        display: flex;
        flex-direction: column;
        & .snippet__body{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            & > .wrapper{
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                min-height: 24px;
            }
        }
        & .snippet__body--clickable{
            cursor: pointer;
        }
        & .snippet__value{
            font-size: 24px;
            line-height: 1.16;
            margin-bottom: 8px;
            color: black;
        }
        & .snippet__unit{
            font-size: 13px;
        }
    }
}
