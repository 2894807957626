@layer components {
    .badge{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-primary);
        color: white;
        font-size: 11px;
        line-height: 1;
        min-height: 18px;
        min-width: 18px;
        max-width: 30px;
        border-radius: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        padding: 4px;
        &::after{
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    .badge--orange{
        background-color: var(--color-orange);
    }
}

