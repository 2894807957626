@layer components {

    .control{

    }

    .control--valid{
        & .control__input{
            border-color: var(--color-primary-hover) !important;
        }
        & .control__msg--valid{
            display: block;
        }
    }

    .control--invalid{
        & > .control__input{
            border-color: var(--color-red) !important;
        }
        & .control__msg--invalid{
            display: block;
        }
    }

    .control--active{

    }

    .control--disabled{

    }

    .control__msg{
        font-size: 13px;
        margin-top: 8px;
    }

    .control__content{

    }

    .control__content--visible{

    }

    .control__activator{

    }

    .control__stub{
        min-height: var(--control-height);
    }

    .control__msg--valid{
        color: var(--color-primary);
        display: none;
    }

    .control__msg--invalid{
        color: var(--color-red);
        display: none;
    }

    .control__append{

    }

    .control__prepend{

    }

    .control__input{
        display: block;
        width: 100%;
        border: 1px solid;
        border-color: var(--color-light3);
        border-radius: var(--border-rad-md);
        font-family: Roboto;
        font-size: 16px;
        font-weight: var(--fw-normal);
        line-height: 1.4;
        color: var(--color-grey1);
        background-color: white;
        box-shadow: var(--shadow-initial);
        outline: none;
        appearance: none;
        transition: border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
        &::placeholder {
            color: var(--color-grey4);
            opacity: 1;
        }
        &::-ms-expand {
            background-color: transparent;
            border: 0;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
        &:focus{
            box-shadow: var(--shadow-btn-hover);
        }
        &:disabled{
            background-color: var(--color-light1);
            opacity: 1;
        }
        &:not(:disabled){
            &:hover{
                border-color: var(--color-light4);
                &::placeholder {
                    color: var(--color-grey3);
                }
            }
            &:focus{

            }
        }
    }

    .control__label{
        font-weight: var(--fw-normal);
        color: var(--color-grey3);
        font-size: 14px;
    }

    .control__label--required{
        &::after{
            content: '*';
            display: inline-block;
            color: var(--color-red);
        }
    }

    .control__indicator{

    }

    .control__icon{

    }

    .control--sm{

    }

    .control--rounded{

    }

    .control--text{
        & .control__append,
        & .control__prepend{
            height: inherit;
            padding: var(--control-padding-y) var(--control-padding-x);
            white-space: nowrap;
            background-color: var(--color-grey7);
            border: 1px solid;
            border-color: var(--color-light3);
            border-radius: var(--border-rad-md);
            position: relative;
            z-index: 0;
        }
        & .control__append{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
        & .control__prepend{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }
        &.control--text-appended,
        &.control--text-prepend{
            display: flex;
            align-items: center;
            & > .control__input{
                position: relative;
                z-index: 1;
            }
        }
        &.control--text-appended{
            & > .control__input{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        &.control--text-prepend{
            & > .control__input{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .control--select,
    .control--date,
    .control--time,
    .control--text{
        & .control__input{
            height: var(--control-height);
            padding: var(--control-padding-y) var(--control-padding-x);
            &:not(:disabled){
                &:hover{
                    box-shadow: var(--shadow-btn-hover);
                }
                &:focus{
                    border-color: var(--color-primary-hover);
                    box-shadow: var(--shadow-btn-hover),
                                0 0 0 1px var(--color-primary-hover);
                }
            }
        }
        & textarea.control__input{
            min-height: calc( 2*var(--control-height) );
        }
        &.control--invalid{
            & .control__input{
                border-color: var(--color-red) !important;
                box-shadow: none !important;
            }
        }
        &.control--sm{
            & .control__input{
                height: var(--control-sm-height);
                padding: var(--control-sm-padding-y) var(--control-sm-padding-x);
            }
        }
        &.control--search{
            & .control__input{
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%238E8D8D'/%3E%3Cpath d='M12 14.4142L13.4142 13L19.1507 18.7365L17.7365 20.1507L12 14.4142Z' fill='%238E8D8D'/%3E%3C/svg%3E%0A");
                background-size: 20px 21px;
                background-repeat: no-repeat;
                background-position: calc(100% - 6px) center;
                padding-right: 32px;
            }
        }
    }

    .control--inputset{
        & > .control__content{
            position: relative;
            & > .control__indicator{
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                white-space: nowrap;
                border-radius: var(--border-rad-md);
                user-select: none;
            }
        }
    }

    .control--text-inputarea{
        & textarea.control__input{
            min-height: var(--control-height);
            resize: none;
        }
        &.control--sm{
            & textarea.control__input{
                min-height: var(--control-sm-height);
            }
        }
    }

    .control--text-multiline{
        position: relative;
        z-index: 1;
        font-family: Roboto;
        font-size: 16px;
        font-weight: var(--fw-normal);
        line-height: 1.4;
        & .control__input{
            resize: none;
            font: inherit;
            line-height: inherit;
        }
        & .wrapper--control-counter{
            font: inherit;
            line-height: inherit;
            padding: var(--control-padding-y) var(--control-padding-x);
        }
        &.control--sm{
            .wrapper--control-counter{
                padding: var(--control-sm-padding-y) var(--control-sm-padding-x);
                & .wrapper__before{
                    right: calc( 100% + var(--control-sm-padding-x) );
                }
            }
        }
    }

    .control--checkbox,
    .control--radio,
    .control--switch{
        position: relative;
        user-select: none;
        margin: 0;
        min-width: 20px;
        color: var(--color-grey3);
        &:hover{
            & input{
                &:not(:disabled){
                    & + .control__label{
                        border-color: var(--color-primary);
                        box-shadow: var(--shadow-checkbox);
                    }
                }
            }
        }
        & input{
            width: auto;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
            &:disabled{
                & + .control__label{
                    border-color: var(--color-light2);
                }
                &:checked{
                    & + .control__label{
                        background-color: var(--color-sprout);
                        border-color: var(--color-sprout);
                        background-position: center;
                    }
                }
            }
            &:not(:disabled){
                &:focus{
                    & + .control__label{
                        border-color: var(--color-primary);
                        box-shadow: var(--shadow-checkbox);
                    }
                }
                &:checked{
                    & + .control__label{
                        border-color: var(--color-primary);
                        background-color: var(--color-primary);
                        background-position: center;
                    }
                }
                & ~ *{
                    cursor: pointer;
                }
            }
        }
        & .control__label{
            flex-shrink: 0;
            display: inline-block;
            vertical-align: text-bottom;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            border-radius: var(--border-rad-md);
            background-color: white;
            border: 1px solid;
            border-color: var(--color-light4);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill='%23fff' d='M0 4.84l1.02-1.031 5.1 5.155-1.021 1.03L0 4.84zm4.591 3.608L12.753.201a.714.714 0 0 1 1.02 0 .734.734 0 0 1 0 1.031L5.611 9.479a.715.715 0 0 1-1.226-.517.736.736 0 0 1 .215-.514h-.009z'/%3E%3C/svg%3E%0A");
            background-position: -30px;
            background-repeat: no-repeat;
            background-size: 13px 9px;
        }
        & .control__msg{
            cursor: initial !important;
            user-select: text;
        }
        &.control--reverse{
            display: inline-flex;
            flex-direction: row-reverse;
            & .control__label{
                margin-right: 0;
                margin-left: 8px;
            }
        }
        &.control--invalid{
            & .control__label{
                border-color: var(--color-red) !important;
            }
            & + .control__msg--invalid{
                display: block;
            }
        }
    }

    .control--radio{
        & .control__label{
            border-radius: 50%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23fff'/%3E%3C/svg%3E%0A");
            background-size: 12px;
        }
    }

    .control--switch{
        &:hover{
            & input{
                &:not(:disabled){
                    & + .control__label{
                        border-color: var(--color-grey4);
                        box-shadow: var(--shadow-btn-hover);
                    }
                }
            }
        }
        & input{
            &:disabled{
                & + .control__label{
                    border-color: var(--color-zanah);
                    background-color: var(--color-zanah);
                }
                &:checked{
                    & + .control__label{
                        border-color: var(--color-zanah);
                        background-color: var(--color-zanah);
                        background-position: 7px 50%;
                        &::after{
                            left: 23px;
                        }
                    }
                }
            }
            &:not(:disabled){
                &:focus{
                    & + .control__label{
                        border-color: var(--color-grey4);
                        box-shadow: var(--shadow-btn-hover);
                    }
                }
                &:checked{
                    & + .control__label{
                        border-color: var(--color-main-green);
                        background-color: var(--color-main-green);
                        background-position: 7px 50%;
                        &::after{
                            left: 23px;
                        }
                    }
                }
            }
        }
        & .control__label{
            width: 38px;
            height: 18px;
            background-color: var(--color-quill-gray);
            border-radius: calc(4*var(--border-rad-lg));
            border-color: var(--color-quill-gray);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 8'%3E%3Cpath stroke='%23F4F4F4' stroke-width='1.5' d='M1 3l3.5 3.5L10 1'/%3E%3C/svg%3E");
            background-position: -13px 50%;
            background-size: 11px 8px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                left: 3px;
                transform: translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: white;
                border-radius: 50%;
                transition: left var(--anim-dur);
            }
        }
    }

    .control--file{
        &.control--disabled{
            & .control__content{
                border-color: var(--color-grey4) !important;
                background-color: var(--color-light1);
            }
            & .control__label{
                color: var(--color-grey3);
            }
            & .control__icon{
                color: var(--color-grey3);
            }
        }
        & .control__content{
            position: relative;
            border: 1px dashed;
            border-color: var(--color-primary);
            border-radius: var(--border-rad-md);
            min-height: var(--control-height);
            &:hover,
            &:focus{
                border-color: var(--color-primary-hover);
            }
        }
        & .control__input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            padding: 0;
            box-shadow: none;
            outline: 0;
        }
        & .control__label{
            display: flex;
            position: absolute;
            z-index: 1;
            width: calc(100% - 80px);
            color: var(--color-grey3);
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;
            left: 34px;
            margin: 0;
            font-weight: var(--fw-normal);
            & > span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        & .control__icon{
            color: var(--color-primary);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
        }
        & .control__action{
            display: none;
            background: 0 0;
            border: 0;
            box-shadow: none;
            padding: 0;
            outline: 0;
            color: var(--color-grey4);
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            &:hover{
                color: var(--color-red);
            }
        }
        & .file{
            white-space: nowrap;
            overflow:  hidden;
            text-overflow: ellipsis;
        }
        & .file__name{
            font-size: 16px;
            color: var(--color-grey1);
            display: inline;
        }
        & .file__info{
            display: inline;
            font-size: 12px;
            color: var(--color-grey4);
        }
        & .file__extention{

        }
        &.control--sm{
            & .control__content{
                min-height: var(--control-sm-height);
            }
        }
        &.control--invalid{
            & .control__content{
                border-color: var(--color-red) !important;
            }
        }
    }

    .control--select{
        & .control__input{
            margin: 0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8'%3E%3Cpath fill='%2361605F' fill-rule='evenodd' d='M12 2L10.59.59 6 5.17 1.41.59 0 2l6 6 6-6z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
            background-position: calc(100% - 12px) center;
            background-repeat: no-repeat;
            background-size: 12px 8px;
            text-overflow: ellipsis;
            padding-right: 32px;
            @mixin scrollThin;
            &[multiple]{
                background-image: none;
                height: auto;
            }
        }
        & option {
            font-weight: var(--fw-normal);
        }
        &.control--sm{
            & .control__input{
                padding-right: 32px;
            }
        }
    }

    .control--select-lang{
        & .control__input{
            padding: 16px;
            margin: 0;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAABC1BMVEUAAABRUVFZWVlaWlpaWlpYWFhZWVlaWlpfX19ZWVlZWVlaWlpZWVlcXFxdXV1jY2NgYGBgYGBeXl5eXl5hYWFWVlZXV1dZWVlYWFhXV1dYWFhYWFhZWVldXV1bW1tdXV1bW1tZWVlWVlZXV1dZWVlcXFxaWlpaWlpfX19fX19hYWFWVlZXV1dZWVlcXFxcXFxVVVVaWlpcXFxTU1NZWVlfX19aWlpbW1teXl5bW1tYWFhZWVlaWlpgYGBeXl5WVlZYWFhaWlpWVlZVVVVdXV1YWFhdXV1YWFhhYWFgYGBaWlplZWVkZGRVVVVbW1tQUFBLS0txcXFZWVlwcHBaWlp2dnZgYGBfX19TU1MoGlIFAAAAWHRSTlMAi7unt6rShUPCwLGuop6JiWhZMCH+6trWyKiel3hbSyv349qilYyCYh0E9dW/tKujmpCIenVzbWVjXU5IPBjRzMrBraWkioeFcTgmC8rEta9+fXRVKBMHdfKHmwAAAXtJREFUKM9t0WdvgkAcBvAHjg2CFFmKe8/aulpXd9XaPfn+n6RwMcY0/SV3by557j+wU8iwjlwq+UEBh1JaqLpXP1axYttkgb1JOzTBPQKjOvyifIqd6qVropYA0BK+wZocD2qjj28BRUKkImLrQc3S/HCiA80uYmd8dMRFuAWg1hQnm89WEFuqzVndDmo9QBJQHa/SHS9N7iqEL3HaezcHIQ+SbckATk7mU9OczuUZcMRBPAbXnBMAZAoqcQQUWFgsNJzXAWQeQJ1GD0gDPLzklW8YRvnYSEbuLxTDWJf19QW8ge8zDOPeMJRwzTADtz8Q/o0icRS7zJM4Nvfn87hcJS43kHKNRk5S8rtyaYMT8a5T1tL9PtEcjh+ytEH0krIznA0TiDXV1KdoB2KPDnGsRze7H2LjfhHSPY6CUQLoSvRvESkPN2+g9KdOAzUdEWEJzlRfsbMphud0tR8rXBflKva+XkLefW5Z7Uzb5iUcsm4Vhy2XLjMWQP0ChTkrg3G2+KMAAAAASUVORK5CYII=');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: none;
        }
    }

    .control--color{
        & .control__input{
            width: 40px;
            height: var(--control-sm-height);
            padding: 0;
            border: none;
            &::-webkit-color-swatch-wrapper {
                padding: 0;
            }
            &::-moz-focus-inner {
                padding: 0;
            }
            &::-webkit-color-swatch {
                border: 1px solid;
                border-color: black;
                border-radius: var(--border-rad-md);
                box-shadow: none;
            }
            &::-moz-focus-inner {
                border: 1px solid;
                border-color: black;
                border-radius: var(--border-rad-md);
                box-shadow: none;
            }
            &::-moz-color-swatch {
                border: 1px solid;
                border-color: black;
                border-radius: var(--border-rad-md);
                box-shadow: none;
            }
        }
    }

    .control--tabs{
        margin-bottom: 30px;
        & > .wrapper{
            display: flex;
            margin-bottom: 15px;
            flex-wrap: wrap;
            width: 100%;
            & .control__label{
                cursor: pointer;
                position: relative;
                padding-bottom: 10px;
                &:not(:last-child){
                    margin-right: 15px;
                }
            }
        }
        & > .wrapper{
            & > .control__input{
                width: auto;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                &:checked{
                    & + .control__label{
                        color: var(--color-primary);
                        &::after{
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: -2px;
                            right: 0;
                            width: 100%;
                            height: 3px;
                            background-color: var(--color-primary);
                        }
                    }
                }
                &:focus{
                    & + .control__label{

                    }
                }
                &:disabled{
                    & + .control__label{

                    }
                }
                &:not(:disabled){
                    & + .control__label{
                        cursor: pointer;
                        &:hover{
                            color: var(--color-primary-hover);
                        }
                    }
                }
            }
        }
        & > .control__content{
            display: none;
            @mixin scrollThin;
        }
        & > .control__content--visible{
            display: block;
        }
    }

    .control--numbergroup{
        display: flex;
        flex-shrink: 0;
        position: relative;
        width: 120px;
        & > .btn{
            position: absolute;
            top: 0;
            height: var(--control-height);
            width: var(--control-height);
            min-width: 0;
            min-height: 0;
            color: var(--color-primary);
            &:first-child{
                left: 0;
            }
            &:last-child{
                right: 0;
            }
            &:disabled{
                color: var(--color-light3);
            }
        }
        & > .control__input{
            height: var(--control-height);
            padding: var(--control-padding-y) var(--control-height);
            text-align: center;
            &:focus{
                border-color: var(--color-primary-hover);
                box-shadow: var(--shadow-btn-hover),
                            0 0 0 1px var(--color-primary-hover);
            }
        }
        &.control--invalid{
            & > .control__input{
                &:focus{
                    border-color: var(--color-red);
                    box-shadow: var(--shadow-btn-hover),
                                0 0 0 1px var(--color-red);
                }
            }
        }
        &.control--disabled{
            & > .btn{
                pointer-events: none;
                color: var(--color-light3);
            }
        }
        &.control--sm{
            & > .btn{
                height: var(--control-sm-height);
                width: var(--control-sm-height);
            }
            & > .control__input{
                height: var(--control-sm-height);
                padding: var(--control-sm-padding-y) var(--control-sm-height);
            }
        }
    }

    .control--collapse{
        margin-bottom: 30px;
        & > .control__label{
            all: unset;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            & > .control__wrapper{
                & > .wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                }
            }
            & .control__indicator{
                padding-left: 15px;
                flex-shrink: 0;
                & .icon__vue{
                    transform: rotate(180deg);
                    transition: transform var(--anim-dur);
                }
            }
        }
        & > .control__content{
            display: none;
        }
        &.control--active{
            & > .control__label{
                & .control__indicator{
                    & .icon__vue{
                        transform: rotate(0deg);
                    }
                }
            }
            & > .control__content{
                display: block;
            }
        }
        &.control--collapse-inverse {
            display: flex;
            flex-direction: column;
            & >.control__label {
                order: 2;
            }
            & >.control__content {
                order: 1;
            }
        }
        &.control--collapse-wide{
            & > .control__label{
                & > .control__wrapper{
                    flex-grow: 1;
                }
                & .control__indicator{
                    margin-left: auto;
                }
            }
        }
    }

    .control--dropdown{
        display: inline-flex;
        align-items: center;
        position: relative;
        width: auto;
        max-width: none;
        & > .control__activator{
            cursor: pointer;
        }
        &.control--active{
            & > .control__wrapper{
                display: inline-flex;
            }
            & > .control__activator{
                visibility: visible !important;
            }
        }
        &.control--disabled{
            & > .control__activator{
                pointer-events: none;
                & .btn{
                    color: var(--color-sprout);
                    background-color: var(--color-zanah);
                    border-color: var(--color-zanah);
                }
                & .btn--outline,
                & .btn--outline-primary{
                    background-color: white;
                    border-color: var(--color-light2);
                }
                & .btn--action,
                & .btn--link{
                    color: var(--color-light3);
                }
            }
        }
        & .control__wrapper{
            display: none;
            position: absolute;
            z-index: var(--z-index-dropdown);
            top: 50%;
            right: 100%;
            flex-direction: column;
            border: 1px solid;
            border-color: var(--color-primary);
            box-shadow: var(--shadow-footer-front);
            border-radius: var(--border-rad-md);
            color: white;
            background-color: var(--color-primary);
            text-align: left;
            max-width: none;
            min-width: 100%;
        }

        & .control__indicator{
            display: none;
        }

        & .control__content{
            padding: 0 var(--dropdown-px);
            flex-grow: 1;
            margin-top: var(--dropdown-py);
            margin-bottom: var(--dropdown-py);
            max-height: 80vh;
            & .menu{
                list-style: none;
                padding-left: 0;
            }
            & > .menu{
                margin-top: 0;
                margin-bottom: 0;
                max-width: none;
                margin-left: calc( -1*var(--dropdown-px) );
                margin-right: calc( -1*var(--dropdown-px) );
                & > .menu__item{
                    & > .nav__item{
                        padding-left: var(--dropdown-px);
                        padding-right: var(--dropdown-px);
                    }
                    & > .menu{
                        & > .menu__item{
                            & > .nav__item{
                                padding-left: calc( 1.5*var(--dropdown-px) );
                                padding-right: var(--dropdown-px);
                            }
                        }
                    }
                }
            }
        }
        & .preloader{
            color: white;
        }
        & .badge{
            background-color: white;
            color: var(--color-primary);
        }
    }

    .control--dropdown-header{
        & .control__wrapper{
            width: 100vw;
            max-width: 200px;
            right: 0px;
            top: calc(100% + 6px);
        }
        & .control__content{
            overflow: auto;
            @mixin scrollThin;
        }
        & .control__indicator{
            display: block;
            width: 12px;
            height: 6px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 15'%3E%3Cpath fill='%2375B94E' d='M11.222.962a1 1 0 011.556 0l10.03 12.41A1 1 0 0122.03 15H1.97a1 1 0 01-.778-1.629L11.222.962z'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: -6px;
            right: 15px;
        }
    }

    .control--dropdown-reverse{

    }

    .control--dropdown-constructor{
        & .control__wrapper{
            color: black;
            background-color: white;
            top: calc(100% - 2px);
            left: 0;
            width: 100vw;
            max-width: max-content;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        & .control__content{
            margin-top: 0;
            margin-bottom: 0;
            overflow-y: auto;
        }
        & .control__activator{
            & .icon__vue{
                transition: transform var(--anim-dur);
            }
        }
        & .nav__item{
            max-width: 250px;
            & .text{
                width: 100vw;
                max-width: max-content;
            }
        }
        &.control--active{
            & .control__activator{
                & .icon__vue--down{
                    transform: rotate(180deg);
                }
            }
        }
        &.control--dropdown-reverse{
            & .control__wrapper{
                left: auto;
                right: 0;
            }
        }
    }

    .control--dropdown-bubble{
        & .control__wrapper{
            border-color: var(--color-grey2);
            box-shadow: var(--shadow-footer-front);
            border-radius: var(--border-rad-md);
            background-color: var(--color-grey2);
            width: 100vw;
            max-width: 400px;
        }
    }

    .control--dropdown-actions{
        & .control__wrapper{
            box-shadow: none;
            border-radius: var(--border-rad-md);
            border-color: var(--color-light3);
            background-color: var(--color-light1);
            color: black;
        }
        & .control__content{
            margin-top: 0;
            margin-bottom: 0;
            & .list{
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;
                max-width: none;
                width: auto;
                margin-left: calc(-1*var(--dropdown-px));
                margin-right: calc(-1*var(--dropdown-px));
                & .list__item{
                    padding: 5px var(--dropdown-px);
                    &:hover{
                        background-color: var(--color-light2);
                    }
                    &:not(:last-of-type){
                        border-bottom: 1px solid;
                        border-color: var(--color-light3);
                    }
                }
            }
            & .icon__wrapper{
                width: 24px;
            }
            & .icon__vue{
                transition: color 0s;
            }
            & .btn--action{
                width: 100%;
                padding: 0;
                justify-content: flex-start;
            }
            & .btn__text{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2;
            }
        }
    }

    .control--range{
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        & > .control__label{
            font-size: 15px;
            color: var(--color-grey4);
        }
        & .control__content{
            position: relative;
            flex-grow: 1;
        }
        & .control__input{
            -webkit-appearance: none;
            width: 100%;
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
            min-width: 50px;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
            }
            &:focus {
                outline: none;
            }
            &::-ms-track {
                width: 100%;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                color: transparent;
            }
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                margin-top: -8px;
                @mixin controlRangeThumb;
            }
            &::-moz-range-thumb {
                @mixin controlRangeThumb;
            }
            &::-ms-thumb {
                margin: 0px;
                @mixin controlRangeThumb;
            }
            &::-webkit-slider-runnable-track {
                width: 100%;
                height: 8px;
                cursor: pointer;
                @mixin controlRangeTrack;
            }
            &::-moz-range-track {
                width: 100%;
                height: 8px;
                cursor: pointer;
                @mixin controlRangeTrack;
            }
            &::-ms-track {
                width: 100%;
                height: 8px;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                border-width: 16px 0;
                color: transparent;
            }
            &::-ms-fill-lower{
                @mixin controlRangeTrack;
            }
            &::-ms-fill-upper {
                @mixin controlRangeTrack;
            }
            &:focus::-ms-fill-lower {
                background: var(--color-primary-hover);
            }
            &:focus::-ms-fill-upper {
                background: var(--color-primary-hover);
            }
            &:focus::-webkit-slider-runnable-track {
                background: var(--color-primary-hover);
            }
        }
        & .control__indicator{
            min-width: 30px;
            height: 24px;
            position: absolute;
            z-index: 1;
            top: calc( 50% - 30px );
            left: 0;
            border: 1px solid;
            border-color: var(--color-light3);
            border-radius: 5px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%) translateY( -50% );
            pointer-events: none;
            padding-left: 4px;
            padding-right: 4px;
            & > .text{
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 13px;
                color: var(--color-grey1);
            }
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: 14px;
                height: 7px;
                z-index: 1;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 7'%3E%3Cpath fill='%23FFFFFF' d='M0 .5V0h14v.5h-1l-5 5H6l-5-5H0Z'/%3E%3Cpath fill='%23DCE2DA' d='M14 0h-.171a3 3 0 0 0-2.122.879l-4 4a1 1 0 0 1-1.414 0l-4-4A3 3 0 0 0 .172 0H0v1h.172a2 2 0 0 1 1.414.586l4 4a2 2 0 0 0 2.829 0l4-4A2 2 0 0 1 13.829 1H14V0Z'/%3E%3C/svg%3E%0A");
                background-position: center;
                background-size: contain;
            }
        }
        &.control--disabled{
            --range-disabled-bg: color-mix(in srgb, var(--color-primary) 50%, var(--color-light1) 50%);
            & .control__input{
                &::-webkit-slider-thumb{
                    @mixin controlRangeThumb var(--range-disabled-bg), white;
                }
                &::-moz-range-thumb{
                    @mixin controlRangeThumb var(--range-disabled-bg), white;
                }
                &::-ms-thumb{
                    @mixin controlRangeThumb var(--range-disabled-bg), white;
                }
                &::-webkit-slider-runnable-track{
                    @mixin controlRangeTrack var(--range-disabled-bg);
                }
                &::-moz-range-track{
                    @mixin controlRangeTrack var(--range-disabled-bg);
                }
                &::-ms-fill-lower{
                    @mixin controlRangeTrack var(--range-disabled-bg);
                }
                &::-ms-fill-upper {
                    @mixin controlRangeTrack var(--range-disabled-bg);
                }
                &:focus::-ms-fill-lower {
                    background: var(--color-primary-hover);
                }
                &:focus::-ms-fill-upper {
                    background: var(--color-primary-hover);
                }
                &:focus::-webkit-slider-runnable-track {
                    background: var(--color-primary-hover);
                }
            }
        }
        &.control--invalid{
            & .control__indicator{
                & > .text{
                    color: var(--color-red);
                }
            }
        }
    }

    .control--video-annotator{
        &.control--disabled{

        }
        & canvas{
            display: block;
            max-width: none;
            background-color: var(--color-light2);
            /*box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);*/
            pointer-events: none;
        }
        & > .control__wrapper{
            overflow-x: auto;
            padding-bottom: 12px;
            padding-top: 44px;
            position: relative;
        }
        & .wrapper--track{
            display: flex;
            position: relative;
            max-width: none;
        }
        & .wrapper--video{
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            & .preloader{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            & video{
                max-height: 50vh;
            }
        }
        .slider{
            width: 1px;
            height: calc( 100% + 20px );
            position: absolute;
            top: -10px;
            background-color: color-mix(in srgb, var(--color-red) 90%, transparent 10%);
            pointer-events: none;
        }
        & .note{
            user-select: none;
            color: var(--color-grey1);
            background: color-mix(in srgb, var(--color-red) 30%, transparent 70%);
            border: 1px solid;
            border-color: color-mix(in srgb, black 20%, transparent 80%);
            top: 0px;
            height: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.note--disabled{
                pointer-events: none;
            }
            & .note__resize{
                display: block;
                position: absolute;
                top: 0;
                height: 100%;
                background: linear-gradient(to bottom, color-mix(in srgb, var(--color-red) 90%, transparent 10%) 0%, color-mix(in srgb, var(--color-red) 90%, transparent 10%) 100%);
                background-repeat: no-repeat;
                background-size: 1px 100%;
                cursor: ew-resize;
                &::before{
                    content: '';
                    display: block;
                    color: white;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: color-mix(in srgb, var(--color-red) 90%, transparent 10%);
                    position: absolute;
                    top: 0;
                }
            }
            & .note__resize--left{
                left: 0;
                background-position-x: left;
                &::before{
                    left: 0;
                    transform: translate( -50%, -50% );
                }
            }
            & .note__resize--right{
                right: 0;
                background-position-x: right;
                &::before{
                    right: 0;
                    transform: translate( 50%, -50% );
                }
            }
        }
        & .table__row{
            & .btn{
                min-width: 32px;
            }
            & .control--text{
                min-width: 200px;
            }
            & > td:first-of-type{
                cursor: pointer;
                background-color: color-mix(in srgb, var(--color-sprout) 15%, transparent 85%) !important;
            }
        }
        & .table__row--active{
            background-color: color-mix(in srgb, var(--color-orange) 15%, transparent 85%) !important;
        }
        & .wrapper--notes-table{
            display: block;
            margin-top: 20px;
            & .table_{
                & thead{
                    display: none;
                }
            }
            & .table__row{
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 0 1px var(--color-light2);
                margin-bottom: 15px;
            }
            & .multiselect{
                &.multiselect--active{
                    min-width: 200px;
                }
            }
        }
        & .multiselect{
            &.speed,
            &.format{
                &.multiselect--active{
                    min-width: 150px;
                }
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            & > *{
                width: 100%;
                order: 4;
            }
            & .wrapper--video{
                order: 1;
                max-width: 60%;
                height: 350px;
                & video{
                    max-height: 100%;
                }
            }
            & .wrapper--notes-table{
                order: 2;
                max-width: calc(40% - 20px);
                max-height: 350px;
                overflow-y: auto;
                padding-bottom: 6px;
                margin-top: 0;
                & .table_{
                    display: table;
                    & thead{
                        display: table-header-group;
                    }
                }
                & .table__row{
                    display: table-row;
                    box-shadow: none;
                    margin-bottom: 0;
                    & > td:first-of-type{
                        text-align: center;
                    }
                }
            }
            & > .control__wrapper{
                order: 3;
            }
        }
    }

    .control--video{
        & .control__wrapper{
            width: 100%;
            display: flex;
            align-items: center;
        }
        & .control__content{

        }
    }

    .control--audio{
        & .control__wrapper{
            width: 100%;
            display: flex;
            align-items: center;
        }
        & .control__content{
            width: 100%;
        }
        & audio{
            width: 100%;
        }
    }

    .control--text-annotator {
        background: white;
        /*box-shadow: var(--shadow-small);*/
        /*padding: 32px;*/
        padding: 16px 0;
        white-space: pre-line;
        font-size: 18px;
        font-weight: var(--fw-normal);

        & ::selection {
            color: black;
            background-color: var(--color-yellow);
        }

        &.control--disabled {
            user-select: none;
        }

        & .icon__vue {
            transition: transform var(--anim-dur);
        }

        & .note--active {
            & .control__activator {
                & .icon__vue--down {
                    transform: rotate(180deg);
                }
            }

            & .control__content {
                padding: 8px;
                position: absolute;
                top: calc(100% + 4px);
                left: 0;
                width: 100%;
                background: white;
                box-shadow: var(--shadow-btn-hover);
                border-radius: var(--border-rad-lg);

                & > .control--text {
                    display: block;
                }
            }
        }

        & .note {
            display: inline-flex;
            align-items: stretch;
            flex-wrap: wrap;
            background: var(--color-yellow);
            padding-left: 8px;
            padding-right: 8px;
            margin-top: 1px;
            margin-bottom: 1px;
            font-size: 20px;
            font-weight: var(--fw-medium);
            position: relative;

            & > .note__label {
                width: 100%;
                cursor: context-menu;
            }
        }

        & .note__resize{
            display: flex;
            align-items: center;
            margin-left: 0px;
            & .btn--action{
                width: 8px;
                min-width: 8px;
                &:first-of-type{
                    margin-right: 4px;
                    & .icon__wrapper{
                        transform: rotate(90deg);
                    }
                }
                &:last-of-type{
                    & .icon__wrapper{
                        transform: rotate(-90deg);
                    }
                }
                & .icon__wrapper{
                    transform-origin: 50% 50%;
                    width: 8px;
                    min-width: 8px;
                    justify-content: center;
                }
                & .icon__vue{
                    width: 24px;
                    min-width: 24px;
                }
            }
        }

        & .note__resize--begin{
            margin-right: 0px;
            padding-right: 14px;
            background: linear-gradient(to right, black 0%,black 100%), linear-gradient(to right, black 0%,black 100%), linear-gradient(to right, black 0%,black 100%);
            background-size: 1px 100%, 8px 1px, 8px 1px;
            background-position: 28px 0, 28px 0, 28px 100%;
            background-repeat: no-repeat, no-repeat, no-repeat;
        }

        & .note__resize--end{
            margin-right: 8px;
            padding-left: 14px;
            background: linear-gradient(to right, black 0%,black 100%), linear-gradient(to right, black 0%,black 100%), linear-gradient(to right, black 0%,black 100%);
            background-size: 1px 100%, 8px 1px, 8px 1px;
            background-position: calc(100% - 28px) 0, calc(100% - 28px) 0, calc(100% - 28px) 100%;
            background-repeat: no-repeat, no-repeat, no-repeat;
        }

        & .note__label{
            white-space: pre-wrap;/* чтобы не схлопывались пробелы */
        }

        & .multiselect {
            display: inline-flex;
            align-items: center;
            min-height: 32px;
            position: static;
            width: auto;
            flex-grow: 1;

            & .multiselect__tags {
                order: 1;
                min-height: 32px;
                flex-grow: 1;
                padding: 0;
                border: none;
                box-shadow: none !important;
                background-color: transparent;
                margin-right: 2px;
                cursor: context-menu;
            }

            & .multiselect__select {
                order: 2;
                position: static;
                transform: none;
                margin-right: 6px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 13 8'%3E%3Cpath fill='%23001016' fill-rule='evenodd' d='m12.295 1.705-1.41-1.41-4.59 4.58-4.59-4.58-1.41 1.41 6 6 6-6Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                background-size: 12px 7px;
                background-repeat: no-repeat;
            }

            &.multiselect--above {
                & .multiselect__content-wrapper {
                    top: auto;
                    bottom: 100%;
                }
            }

            & .multiselect__content-wrapper {
                top: 100%;
                left: 0;
                min-width: 240px;
            }

            & .multiselect__single {
                color: var(--color-grey1);
                white-space: nowrap;
                font-size: 13px;
                font-weight: var(--fw-medium);
            }
        }

        & .multiselect__select,
        & .btn--action {
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
        }

        & .btn--action {
            order: 3;
            color: black;
            &:disabled{
                color: var(--color-grey2);
            }
            &:not(:disabled){
                &:hover,
                &:focus{
                    color: color-mix(in srgb, var(--color-red) 90%, black 10%);
                }
            }
        }

        & .multiselect__tags-wrap,
        & .control__lines-wrap {
            flex-direction: column;
        }

        & .control__lines-wrap {
            display: flex;
        }

        & .multiselect__tag,
        & .control__line {
            padding: 1px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--color-grey1);
            font-size: 13px;
            font-weight: var(--fw-medium);
            border: none;
            border-radius: 0;
            background-color: transparent;

            & .multiselect__tag-icon {
                display: none;
            }
        }

        & .control__wrapper {
            display: flex;
            align-items: center;
        }

        & .control__activator {
            display: inline-flex;
            align-items: center;
            min-height: 32px;
        }

        & .control__content {
            & > .control--text {
                display: none;
            }

        }

        @media (min-width: theme(--breakpoint-sm) ) {
            & .note {
                flex-wrap: nowrap;
            }
            & .multiselect__tags-wrap,
            & .control__lines-wrap {
                flex-wrap: nowrap;
                flex-direction: row;
            }
            & .multiselect__tag,
            & .control__line {
                padding: 0 4px;
                margin: 0;
                overflow: initial;

                &:not(:first-child) {
                    border-left: 1px solid;
                    border-color: var(--color-grey2);
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .control--hierarchical-select{
        position: relative;
        &.control--disabled {
            & .control__tags {
                /*border-color: var(--color-grey6) !important;*/
                /*background-color: var(--color-light1);*/
                /*box-shadow: none !important;*/
            }
            & .list__item{
                cursor: initial;
            }
        }
        &.control--active{
            & .control__content {
                display: block;
            }
            & .control__tags {
                border-color: var(--color-primary-hover);
                box-shadow: var(--shadow-btn-hover),
                            0 0 0 1px var(--color-primary-hover);
                &:hover {
                    border-color: var(--color-primary-hover);
                    box-shadow: var(--shadow-btn-hover),
                                0 0 0 1px var(--color-primary-hover);
                }
            }
            & .control__indicator {
                & .icon__vue--down {
                    transform: rotate(180deg);
                }
            }
        }
        &.control--hierarchical-select-multiple{
            & .control__tag {
                max-width: 100%;
                position: relative;
                display: inline-block;
                padding: 3px 30px 3px 10px;
                border-radius: var(--border-rad-lg);
                margin-right: 4px;
                color: var(--color-grey1);
                font-size: 14px;
                line-height: 1;
                background-color: var(--color-grey7);
                border: 1px solid;
                border-color: var(--color-grey6);
                margin-top: 2px;
                margin-bottom: 2px;
                & .btn--action {
                    display: inline-flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    &:hover {
                        background-color: white !important;
                        color: var(--color-grey2);
                    }
                }
                & .icon__vue {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        &.control--hierarchical-select-top{
            & .control__content{
                top: auto;
                bottom: calc(100% + 4px);
                flex-direction: column-reverse;
            }
        }
        &.control--hierarchical-select-multiline{
            & .control__tag {
                white-space: normal;
            }
            & .list__item-title {
                & > .text{
                    white-space: normal;
                }
            }
            & .list__item {
                & > .wrapper{
                    min-height: 40px;
                    height: auto;
                }
            }
        }
        & .control__tags{
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: var(--control-height);
            padding: 7px var(--control-padding-x);
            width: 100%;
            border: 1px solid;
            border-color: var(--color-light3);
            border-radius: var(--border-rad-md);
            font-family: Roboto;
            font-size: 15px;
            font-weight: var(--fw-normal);
            line-height: 1.4;
            color: var(--color-grey1);
            background-color: white;
            box-shadow: var(--shadow-initial);
            outline: none;
            appearance: none;
            transition: border-color .15s ease-in-out,
                        box-shadow .15s ease-in-out;
            &:hover {
                border-color: var(--color-light4);
                box-shadow: var(--shadow-btn-hover);
            }
        }
        & .control__placeholder{
            user-select: none;
            color: var(--color-light4);
        }
        & .control__tags-wrap{
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            max-width: calc(100% - 32px);
        }
        & .control__tag {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            & .btn--action {
                display: none;
            }
        }
        & .control__indicator {
            display: flex;
            color: var(--color-grey2);
            flex-shrink: 0;
            & > *{
                flex-shrink: 0;
            }
            & .icon__vue--down{
                transform: transform var(--anim-dur);
            }
        }
        & .control__content{
            display: none;
            position: absolute;
            z-index: var(--z-index-dropdown);
            width: 100%;
            left: 0;
            top: calc(100% + 4px);
            background-color: white;
            border-radius: var(--border-rad-lg);
            box-shadow: var(--shadow-btn-hover);
            overflow: hidden;
        }
        & .control__search{
            padding: 12px;
            border-bottom: 1px solid;
            border-color: var(--color-light2);
            & > .wrapper{
                position: relative;
            }
            & .control--text{
                flex-grow: 1;
            }
        }
        & .control__search-actions{
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            & *{
                flex-shrink: 0;
            }
            & > .btn {
                padding: 0;
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                & .icon__vue {
                    width: 18px;
                    height: 18px;
                }
                & .icon__vue--search{
                    width: 24px;
                    height: 24px;
                }
            }
        }
        & .control__search-nooptions,
        & .control__search-noresults{
            padding: 12px;
        }
        & .control__list-wrapper{
            overflow-y: auto;
            max-height: 470px;
            margin-right: 3px;
            margin-top: 3px;
            margin-bottom: 3px;
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--color-light2);
            }
        }
        & .list {
            list-style: none;
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 15px;
            color: var(--color-grey2);
            user-select: none;
            & .list{
                display: none;
            }
        }
        & .list__item {
            position: relative;
            cursor: pointer;
            & > .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: sticky;
                top: calc(var(--depth)*40px);
                z-index: calc(100 - var(--depth));
                height: 40px;
                background: linear-gradient(90deg, white 0%, white 100%),
                            repeating-linear-gradient(90deg, var(--color-light3), var(--color-light3) 0px, white 1px, white 20px);
                background-position: calc(20px*var(--depth)) 0, -1px 0px;
                background-repeat: no-repeat, repeat-x;
                &:hover {
                    background: linear-gradient(90deg, var(--color-light1) 0%, var(--color-light1) 100%),
                                repeating-linear-gradient(90deg, var(--color-light3), var(--color-light3) 0px, var(--color-light1) 1px, var(--color-light1) 20px);
                    background-position: calc(20px*var(--depth)) 0, -1px 0px;
                    background-repeat: no-repeat, repeat-x;
                }
            }
        }
        & .list__item-title {
            padding-right: 6px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: calc(13px + var(--depth)*20px);
            display: flex;
            align-items: center;
            flex-grow: 1;
            line-height: 1.2;
            & > .text{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            & .text--highlighted{
                display: inline-block;
                padding: 1px 2px;
                border-radius: var(--border-rad-sm);
                background-color: color-mix(in srgb, var(--color-yellow) 50%, white);
            }
        }
        & .list__item--expandable {
            & > .wrapper{
                & > .list__item-title{
                    &::before{
                        content: '';
                        display: block;
                        width: 12px;
                        height: 12px;
                        flex-shrink: 0;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 8 12'%3E%3Cpath fill='%2361605F' fill-rule='evenodd' d='M2 12 .59 10.59 5.17 6 .59 1.41 2 0l6 6-6 6Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
                        background-size: 8px 12px;
                        background-repeat: no-repeat;
                        background-position: center;
                        margin-right: 10px;
                        transition: transform var(--anim-dur);
                    }
                }
            }
        }
        & .list__item--expanded {
            & > .wrapper{
                background: linear-gradient(90deg, white 0%, white 100%),
                            linear-gradient(90deg, white 0%, white 100%),
                            repeating-linear-gradient(90deg, var(--color-light3), var(--color-light3) 0px, white 1px, white 20px);
                background-position: calc( 20px*(var(--depth) + 1) ) 0, calc(20px*var(--depth)) 0, -1px 0px;
                background-repeat: no-repeat, no-repeat, repeat-x;
                background-size: 100% 100%, 100% 30px, 100% 100%;
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: calc(20px*(var(--depth) + 1) + 12px);
                    height: 2px;
                    background-color: var(--color-light3);
                }
                &:hover {
                    background: linear-gradient(90deg, var(--color-light1) 0%, var(--color-light1) 100%),
                                linear-gradient(90deg, var(--color-light1) 0%, var(--color-light1) 100%),
                                repeating-linear-gradient(90deg, var(--color-light3), var(--color-light3) 0px, var(--color-light1) 1px, var(--color-light1) 20px);
                    background-position: calc(20px*(var(--depth) + 1)) 0, calc(20px*var(--depth)) 0, -1px 0px;
                    background-repeat: no-repeat, no-repeat, repeat-x;
                    background-size: 100% 100%, 100% 30px, 100% 100%;
                }
                & > .list__item-title {
                    &::before{
                        transform: rotate(90deg);
                    }
                }
            }
            & > .list{
                display: block;
            }
        }
        & .list__item--selected {
            color: var(--color-primary);
            & > .wrapper{
                &::after{
                    content: '';
                    display: block;
                    flex-shrink: 0;
                    width: 18px;
                    height: 18px;
                    margin-right: 13px;
                    margin-left: 1em;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 14'%3E%3Cpath fill='%2375B94E' d='M18 2 6 14 .5 8.5l1.41-1.41L6 11.17 16.59.59 18 2Z'/%3E%3C/svg%3E%0A");
                    background-size: 18px 14px;
                    background-repeat: no-repeat;
                }
                & >.list__item-title {
                    max-width: calc(100% - 45px);
                }
            }
        }
        &.control--sm {
            & .control__tags {
                min-height: var(--control-sm-height);
                padding: 3px 6px;
            }
            & .control__indicator{
                & .icon__vue{
                    width: 22px;
                    height: 22px;
                }
            }
            &.control--hierarchical-select-multiple{
                & .control__tag{
                    padding: 2px 28px 2px 10px;
                    & .btn--action{
                        width: 18px;
                        height: 18px;
                        min-width: 18px;
                        min-height: 18px;
                    }
                }
            }
        }
    }

    .control--image,
    .control--annotator,
    .control--spectrogram,
    .control--videoannotator{
    /*control*/
        &.control--disabled{

        }

        & .control__toolbar{
            margin-bottom: 6px;
            & .list--buttons{
                width: 100%;
            }
            & .list--persistent{
                margin-bottom: 2px;
            }
            & .list--contextual{
                min-height: 42px;
                display: flex;
                align-items: center;
                border: 1px solid;
                border-color: var(--color-grey4);
                border-radius: var(--border-rad-lg);
                padding-left: 4px;
                padding-right: 4px;
            }
            & .list__item--hotkeys{

            }
            & .control--numbergroup{
                user-select: none;
            }
        }

        & .control__output{

        }

    /*workspace*/
        & .workspace{
            width: 100%;
            max-height: 70vh;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 16px;
            border: 1px solid;
            border-color: var(--color-grey4);
            border-radius: var(--border-rad-sm);
            overflow: hidden;
            background-color: var(--color-light1);
            background-image: linear-gradient(45deg, var(--color-light2) 25%, transparent 25%, transparent 75%, var(--color-light2) 75%, var(--color-light2)),
            linear-gradient(45deg, var(--color-light2) 25%, transparent 25%, transparent 75%, var(--color-light2) 75%, var(--color-light2));
            background-size: 10px 10px;
            background-position: 0 0, 5px 5px;
            touch-action: none;
        }
        & .workspace--highlighted{
            outline: 4px solid;
            outline-color: var(--color-eucalyptus);
        }
        & .workspace--img-grab{
            cursor: grab;
            & .workspace__img,
            & .figure--editing,
            & .figure--editing .figure__control,
            & .workspace__bitmap{
                cursor: inherit;
            }
        }
        & .workspace--img-grabbing{
            cursor: grabbing;
            & .workspace__img,
            & .figure--editing,
            & .figure--editing .figure__control,
            & .workspace__bitmap{
                cursor: inherit;
            }
        }
        & .workspace__svg{

        }
        & .workspace__img{

        }
        & .workspace__preloader{
            color: var(--color-primary);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(2);
            pointer-events: none;
        }

        @media (min-width: theme(--breakpoint-lg) ) {
            & .control__output{
                display: flex;
                align-items: flex-start;
            }

            & .workspace{
                max-width: 70%;
                margin-right: 16px;
                margin-bottom: 0;
            }
        }
    }

    .control--image{
        & .control__toolbar{
            & .list__item--hotkeys{
                margin-left: auto;
            }
            & .list--contextual{
                position: absolute;
                z-index: 1;
                background: white;
                width: auto;
                left: 280px;
                top: 75px;
                border: none;
                padding: 0;
                min-height: 0;
                & .list__item{
                    min-height: 42px;
                    display: flex;
                    align-items: center;
                    border: 1px solid;
                    border-color: var(--color-grey4);
                    border-radius: var(--border-rad-lg);
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
        & .workspace{
            max-width: 100%;
            margin-right: 0;
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .control__toolbar{

            }
        }
    }

    .control--annotator{
        & .control__toolbar{
            & .list__item--figures{
                display: flex;
                align-items: center;
                margin-left: auto;
                & .multiselect{
                    & .icon__vue{
                        fill: var(--color-primary);
                        stroke: var(--color-primary);
                        stroke-width: 2;
                        fill-opacity: 0.2;
                        width: 24px;
                        height: 24px;
                    }
                    & .multiselect__content-wrapper{
                        right: 0;
                    }
                }
                & .multiselect__content-wrapper{
                    min-width: 220px;
                    @mixin scrollThin;
                }
                & .multiselect__option{
                    display: flex;
                    align-items: center;
                    & > .icon__wrapper{
                        flex-shrink: 0;
                        margin-right: 12px;
                    }
                }
            }
        }

    /*workspace*/
        & .workspace--editing{
            & .workspace__img,
            & .figure--editing{
                cursor: var(--cursor-add-pic), auto;
            }
        }
        & .workspace--clipping{
            & .figure{
                pointer-events: none;
            }
        }
        & .workspace__guides{
            pointer-events: none;
        }
        & .workspace__sam{
            pointer-events: none;
        }
        & .workspace__bitmap{
            cursor: none;
            & canvas{
                opacity: 0.5;
            }
        }
        & .workspace__bitmap-cursor{
            pointer-events: none;
        }
    /*figure*/
        & .figure{
            cursor: pointer;
        }
        & .figure--clip{
            & .figure__component{
                stroke-dasharray: 20;
                animation: note-editing 25s linear infinite;
            }
        }
        & .figure--editing{
            & .figure__component{
                stroke-dasharray: 20;
                animation: note-editing 25s linear infinite;
            }
            & .figure__control{
                cursor: var(--cursor-remove-pic), auto;
            }
        }
        & .figure--interpolated{
            & .figure__number{
                text-decoration: underline;
            }
        }
        & .figure--drawing{
            pointer-events: none; /* при рисовании фигура "прозрачна" для мыши */
            & .figure__number,
            & .figure__title-wrapper{
                display: none;
            }
        }
        & .figure--moving{
            cursor: pointer;
        }
        & .figure--ungrouped{
            cursor: var(--cursor-add-pic), auto;
        }
        & .figure--grouped{
            cursor: var(--cursor-remove-pic), auto;
        }
        & .figure__component{

        }
        & .figure__component--bitmap{
            & .figure__mask{
                pointer-events: none;
            }
        }
        & .figure__number,
        & .figure__title,
        & .figure__info{
            fill: white;
            font-weight: var(--fw-normal);
            text-shadow: black 0 0 2px;
            font-family: Roboto, Arial, Helvetica, sans-serif;
            line-height: 1;
            pointer-events: none;
            user-select: none;
            transition: font-size var(--anim-dur);
        }
        & .figure__number{

        }
        & .figure__title-wrapper{
            position: relative;
            pointer-events: none;
        }
        & .figure__title-wrapper-with-bg{
            & .figure__title{
                background-color: color-mix(in srgb, black 40%, transparent 60%);
            }
        }
        & .figure__title{
            color: white;
            text-align: center;
            display: inline-block;
            width: max-content;
            padding: 0.5em;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        & .figure__info{

        }
        & .figure__control-wrapper{

        }
        & .figure__control{
            cursor: e-resize;
            &.cursor-e{
                cursor: e-resize;
            }
            &.cursor-n{
                cursor: n-resize;
            }
            &.cursor-ne{
                cursor: ne-resize;
            }
            &.cursor-nw{
                cursor: nw-resize;
            }
            &.cursor-rotation{
                cursor: pointer;
            }
        }
        & .figure__control--disabled{
            pointer-events: none;
        }

    /*group*/
        & .group{

        }

        & .group--editing{
            & .group__bg{
                pointer-events: none;
                stroke-dasharray: 20;
                animation: note-editing 25s linear infinite;
            }
        }

        & .group--unlocked{
            & .group__bg{
                pointer-events: none;
            }
        }

        & .group--hidden{
            display: none;
        }

        & .group__bg{
            cursor: pointer;
        }

        & .group__control{
            cursor: e-resize;
            &.cursor-e{
                cursor: e-resize;
            }
            &.cursor-n{
                cursor: n-resize;
            }
            &.cursor-ne{
                cursor: ne-resize;
            }
            &.cursor-nw{
                cursor: nw-resize;
            }
            &.cursor-rotation{
                cursor: pointer;
            }
        }

        & .group__button{
            cursor: pointer;
        }

    }

    .control--annotator,
    .control--spectrogram,
    .control--videoannotator{
    /*annotations*/
        & .annotations{
            width: 100%;
            & .control--color{
                & .control__input{
                    width: 16px;
                    height: 16px;
                    &::-webkit-color-swatch {
                        border: none;
                        border-color: black;
                        border-radius: 50%;
                        box-shadow: 0px 0px 2px color-mix(in srgb, black 25%, transparent 75%);
                    }
                    &::-moz-focus-inner {
                        border: none;
                        border-color: black;
                        border-radius: 50%;
                        box-shadow: 0px 0px 2px color-mix(in srgb, black 25%, transparent 75%);
                    }
                    &::-moz-color-swatch {
                        border: none;
                        border-color: black;
                        border-radius: 50%;
                        box-shadow: 0px 0px 2px color-mix(in srgb, black 25%, transparent 75%);
                    }
                }
            }
            & .table_{
                display: block;
            }
            & .table__head{
                display: block;
                width: 100%;
                background: linear-gradient(to right, var(--color-grey4) 0%,var(--color-grey4) 100%);
                background-size: 100% 1px;
                background-repeat: no-repeat;
                background-position: 0 calc(100% - 1px);
                background-color: white;
                margin-bottom: 8px;
                & .table__row{
                    border: none;
                    margin-bottom: 0;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                & .table__col{
                    &:not(:last-of-type){
                        padding-right: 6px;
                    }
                }
                & .table__col--index{
                    width: auto;
                }
                & .table__col--index-interpolated{

                }
                & .table__col--input{
                    flex-grow: 1;
                    order: 2;
                    & .label{
                        display: none;
                    }
                }
                & .table__col--actions{
                    order: 3;
                }
            }
            & .table__body{
                display: block;
                width: 100%;
            }
            & .table__row{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border: 1px solid;
                border-color: var(--color-grey4);
                border-radius: var(--border-rad-sm);
                margin-bottom: 8px;
                padding: 6px;
            }
            & .table__row--active{
                background-color: color-mix(in srgb, var(--color-orange) 15%, transparent 85%) !important;
            }
            & .table__col{
                width: 100%;
                padding: 0;
            }
            & .table__col--index{
                order: 1;
                max-width: 50%;
                white-space: nowrap;
                display: flex;
                align-items: center;
                & > .wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                & .text{
                    display: inline-block;
                    margin-right: 6px;
                    cursor: pointer;
                    user-select: none;
                }
            }
            & .table__col--index-interpolated{
                & .text{
                    text-decoration: underline;
                }
            }
            & .table__col--input{
                order: 3;
                & .label{
                    display: block;
                    white-space: nowrap;
                    font-size: 15px;
                    color: var(--color-grey1);
                    font-weight: var(--fw-bold);
                    margin-right: 6px;
                }
                & .multiselect__tag{
                    white-space: initial;
                }
            }
            & .table__col--actions{
                order: 2;
                max-width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                & .list--buttons{
                    flex-wrap: nowrap;
                    & .list__item{
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media (min-width: theme(--breakpoint-lg) ) {
            & .annotations{
                max-width: 30%;
                max-height: 70vh;
                overflow-y: auto;
                align-self: stretch;
                & .table_{
                    display: table;
                }
                & .table__head{
                    display: table-header-group;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    & .table__col--input{
                        display: table-cell;
                        & .label{
                            display: inline-block;
                        }
                    }
                }
                & .table__body{
                    display: table-row-group;
                }
                & .table__row{
                    display: table-row;
                    border: none;
                }
                & .table__col{
                    display: table-cell;
                    padding: 6px;
                }
                & .table__col--index{
                    order: 1;
                    width: auto;
                }
                & .table__col--input{
                    order: 2;
                    width: 100%;
                    & .label{
                        display: none;
                    }
                    & .option__title{
                        display: flex;
                        align-items: center;
                    }
                }
                & .table__col--actions{
                    order: 3;
                    width: auto;
                }
            }
        }
    }

    .control--spectrogram,
    .control--videoannotator{
        & .workspace{
            display: flex;
            flex-direction: column;
            overflow: visible;
            & .preloader{
                margin: 0;
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        & .list__item--hotkeys{
            margin-left: auto;
        }
    }

    .control--diagram{
        flex-shrink: 0;
        width: 100%;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        background-color: var(--color-light1);
        background-image: linear-gradient(45deg, var(--color-light2) 25%, transparent 25%, transparent 75%, var(--color-light2) 75%, var(--color-light2)),
        linear-gradient(45deg, var(--color-light2) 25%, transparent 25%, transparent 75%, var(--color-light2) 75%, var(--color-light2));
        background-size: 10px 10px;
        background-position: 0 0, 5px 5px;
        touch-action: none;
        user-select: none;
        @mixin scrollThin;
        &.control--invisible{
            opacity: 0;
        }
        &.control--disabled{
            & .interval__resize{
                pointer-events: none;
            }
        }
        &.control--highlighted{
            outline: 2px solid;
            outline-color: var(--color-eucalyptus);
        }
        &.control--grab{
            cursor: grab;
        }
        &.control--grabbing{
            cursor: grabbing;
        }
        & .diagram__wrapper{
            display: inline-flex;/* просто flex почему-то схлопывается */
            vertical-align: top; /* иначе отступ снизу */
            width: auto;
            max-width: none;
            align-items: center;
            justify-content: flex-start;
        }
        & canvas{
            max-width: none;
            flex-shrink: 0;
            pointer-events: none;
        }
        & .diagram__completed{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            opacity: 0.2;
            /*mix-blend-mode: lighten;*/
            max-width: none;
            pointer-events: none;
        }
        & .diagram__marker{
            position: absolute;
            top: 0;
            right: 50%;
            width: 1px;
            height: 100%;
            background: var(--color-red);
            pointer-events: none;
        }
        & .diagram__timeline{
            position: absolute;
            top: 0;
            left: 0;
            white-space: nowrap;
            max-width: none;
            overflow: hidden;
            min-width: 100%;
            pointer-events: none;
        }
        & .diagram__timeline-label{
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            &:first-child{
                transform: translateX(0%);
            }
            &:last-child{
                /*transform: translateX(-100%);*/
                /*display: none;*/
            }
        }
        & .diagram__intervals{
            pointer-events: none;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        & .diagram__interval{
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            user-select: none;
            color: var(--color-red);
            border: 1px solid;
            border-color: color-mix(in srgb, black 20%, transparent 80%);
            cursor: pointer;
            max-width: none; /*issue 1924*/
        }
        & .interval__bg{
            pointer-events: all;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: currentColor;
            opacity: 0.3;
        }
        & .interval__title,
        & .interval__number{
            position: relative;
            z-index: 1;
            color: black;
            font-weight: var(--fw-normal);
            text-shadow: white 0 0 2px;
            font-family: Roboto, Arial, Helvetica, sans-serif;
            text-align: center;
        }
        & .interval__title{
            overflow: hidden;
            font-size: 13px;
            padding-left: 4px;
            padding-right: 4px;
        }
        & .interval__title--collapsed{
            background-color: white;
            border: 1px solid;
            border-color: black;
            min-width: 100px;
        }
        & .interval__number{
            white-space: nowrap;
        }
        & .interval__resize{
            pointer-events: all;
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            pointer-events: all;
            background: linear-gradient(to bottom, currentColor 0%,currentColor 100%);
            background-repeat: no-repeat;
            background-size: 1px 100%;
            cursor: ew-resize;
            width: 25%;
            max-width: 5px;
            &::before{
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: currentColor;
                opacity: 0.9;
                position: absolute;
                top: 0;
            }
        }
        & .interval__resize--left{
            left: 0;
            background-position-x: left;
            &::before{
                left: 0;
                transform: translate( -50%, -50% );
            }
        }
        & .interval__resize--right{
            right: 0;
            background-position-x: right;
            &::before{
                right: 0;
                transform: translate( 50%, -50% );
            }
        }
        & .interval__resize--disabled{
            pointer-events: none;
        }
    }

    @supports (mix-blend-mode: lighten) {
        .control--diagram .diagram__completed{
            opacity: 1;
            mix-blend-mode: lighten;
        }
    }

    .control--videoannotator{
        & .control__video-wrapper{
            position: relative;
            width: 100%;
            overflow: hidden;
            &::before{
                content: '';
                display: block;
                padding-top: calc(100% / var(--aspect-ratio));
            }
        }
        & .control__video{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            max-width: 100%;
        }
    }

    .control--sequence-annotator{
        width: 100%;
        & > .list--buttons{
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid;
            border-color: var(--color-grey4);
        }
        & .list__item--speed{
            & .multiselect--active{
                min-width: 100px;
            }
        }
        & .list__item--player{
            flex-grow: 0.4;
            & > .list--buttons{
                flex-grow: 1;
            }
        }
        & .list__item--progress{
            position: relative;
            flex-grow: 1;
            & > .wrapper{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            & .alert{
                min-width: 3.6em;
                text-align: center;
            }
            & .control--range{
                margin-right: 8px;
            }
        }
        & .control--video{
            & .control__content{
                max-width: none !important;
            }
        }
    }

    .control--mushra{
        white-space: nowrap;
        & .btn{
            & > .icon__wrapper{
                flex-shrink: 0;
            }
        }
        & > .control__wrapper{
            margin-bottom: 10px;
            & .list--buttons{
                margin-bottom: 10px;
            }
            & .control__loop{
                flex-grow: 1;
                & > .wrapper{
                    width: 100%;
                    position: relative;
                    height: 32px;
                    background-color: var(--color-light2);
                    margin-bottom: 4px;
                }
                & .control__loop-cursor{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    pointer-events: none;
                    background-color: color-mix(in srgb, var(--color-red) 90%, transparent 10%);
                    pointer-events: none;
                }
                & .control__loop-resize{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 5px;
                    height: 100%;
                    pointer-events: all;
                    background: linear-gradient(to bottom, color-mix(in srgb, var(--color-red) 90%, transparent 10%) 0%, color-mix(in srgb, var(--color-red) 90%, transparent 10%) 100%);
                    background-repeat: no-repeat;
                    background-size: 1px 100%;
                    cursor: ew-resize;
                    &::before{
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: color-mix(in srgb, var(--color-red) 90%, transparent 10%);
                        position: absolute;
                        top: 0;
                    }
                }
                & .control__loop-resize--left{
                    &::before{
                        left: 0;
                        transform: translate(-50%, -50%);
                    }
                }
                & .control__loop-resize--right{
                    background-position-x: right;
                    transform: translateX(-100%);
                    &::before{
                        right: 0;
                        transform: translate(50%, -50%);
                    }
                }
                & .control__loop-scale{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                & .control__loop-skipped{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    background: color-mix(in srgb, var(--color-orange) 20%, transparent 80%);
                }
            }
        }
        & > .control__content{
            overflow: auto;
            & .table_{
                & thead{
                    text-align: center;
                    & th{
                        vertical-align: bottom;
                        &:last-of-type > *{
                            width: calc( var(--range-thumb-w)/2 );
                        }
                    }
                    & .text{
                        display: inline-block;
                    }
                    & .control__label{
                        max-width: none;
                        font-size: 13px;
                        font-weight: var(--fw-normal);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: translateX(-50%);
                    }
                    & .wrapper{
                        position: relative;
                    }
                }
                & tbody{
                    & tr{
                        &:hover{
                            background-color: color-mix(in srgb, var(--color-orange) 15%, transparent 85%);
                        }
                    }
                }
                & th,
                & td{
                    padding: 14px 0;
                    &:first-of-type{
                        padding-left: 10px;
                        padding-right: calc(10px + var(--range-thumb-w)/2 );
                    }
                    &:last-of-type{
                        text-align: center;
                    }
                }
            }
        }
        & .control--audio{
            & audio{
                display: none;
            }
            & .control__content{
                max-width: 100% !important;
            }
        }
        & .control--range{
            width: auto;
            max-width: none;
            margin-left: calc( -1*var(--range-thumb-w)/2 );
            margin-right: calc( -1*var(--range-thumb-w)/2 );
        }
    }

    .control--output-text{
        max-height: 500px;
        overflow-y: auto;
        @mixin scrollThin;
    }

    .control--line-chart{
        &.control--disabled{
            & .highcharts-control-points{
                pointer-events: none;
            }
        }
        & .highcharts-annotation-label,
        & .highcharts-annotation-shapes{
            cursor: auto;
        }
        & .highcharts-navigator-mask-inside{
            cursor: move !important;
        }
        & .highcharts-range-selector-buttons{
            & .highcharts-button{
                font-size: 16px;
            }
        }
        & .workspace{
            margin-bottom: 10px;
        }
        & .annotations{
            width: 100%;
            & .annotations__input-label{
                white-space: nowrap;
                margin-bottom: 4px;
                font-size: 15px;
                color: var(--color-grey1);
                font-weight: var(--fw-bold);
            }
            & .table__head{
                display: block;
                width: 100%;
                box-shadow: 0px 1px 0 var(--color-grey4);
                margin-bottom: 8px;
                & .table__row{
                    border: none;
                    margin-bottom: 0;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                }
                & .table__col{
                    padding-top: 0;
                }
                & .table__col--input{
                    display: none;
                }
                & .table__col--actions{
                    max-width: 100%;
                }
                & .annotations__input-label{
                    display: none;
                }
            }
            & .table__body{
                display: block;
                width: 100%;
            }
            & .table__row{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border: 1px solid;
                border-color: var(--color-grey4);
                border-radius: var(--border-rad-sm);
                margin-bottom: 8px;
            }
            & .table__row--active{
                background-color: color-mix(in srgb, var(--color-orange) 15%, transparent 85%) !important;
            }
            & .table__col{
                display: flex;
                align-items: center;
                width: 100%;
                padding: 6px;
            }
            & .table__col--index{
                max-width: 50%;
                order: 1;
                white-space: nowrap;
                padding-right: 0;
                & .text{
                    display: inline-block;
                    margin-right: 6px;
                }
            }
            & .table__col--input{
                order: 3;
                display: block;
            }
            & .table__col--actions{
                max-width: 50%;
                order: 2;
                justify-content: flex-end;
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .annotations{
                & .table_{
                    display: table;
                }
                & .table__head{
                    display: table-header-group;
                    width: unset;
                    margin-bottom: 0;
                    position: sticky;
                    top: -4px;
                    background-color: white;
                    z-index: 1;
                    & .table__row{

                    }
                    & .table__col--index,
                    & .table__col--input{
                        display: table-cell;
                    }
                    & .table__col--actions{
                        max-width: unset;
                    }
                    & .annotations__input-label{
                        display: table-cell;
                    }
                }
                & .table__body{
                    display: table-row-group;
                    width: unset;
                    & .annotations__input-label{
                        display: none;
                    }
                }
                & .table__row{
                    width: unset;
                    display: table-row;
                    border: none;
                    margin-bottom: 0;
                }
                & .table__col{
                    display: table-cell;
                    width: unset;
                    &:first-of-type{
                        padding-left: 0;
                    }
                    &:last-of-type{
                        padding-right: 0;
                    }
                }
                & .table__col--index{
                    max-width: unset;
                }
                & .table__col--input{
                    display: table-cell;
                }
                & .table__col--actions{
                    max-width: unset;
                }
                & .annotations__input-label{

                }
                & .list--buttons{
                    justify-content: flex-end;
                }
            }
        }
    }

    .control--videorecorder{
        width: 100%;
        & .control__preview{
            margin-bottom: 12px;
            background-color: var(--color-light1);
            display: flex;
            align-items: center;
            justify-content: center;
            & > .wrapper{
                position: relative;
                overflow: hidden;
            }
            & video{
                max-height: 50vh;
                display: block;
            }
            & .control--video .control__content{
                max-width: 100%;
            }
        }
        & .control__toolbar{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &.control--is-recording{
            & .control__preview{
                & > .wrapper{
                    &::after{
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: var(--color-red);
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                }
            }
        }
        @media (min-width: theme(--breakpoint-xl) ) {
            max-width: 70%;
        }
    }

    .control--progress{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        min-width: 100px;
        height: 10px;
        background-color: var(--color-grey6);
        border-radius: var(--border-rad-sm);
        &::-webkit-progress-bar{
            background-color: var(--color-grey6);
            border-radius: var(--border-rad-sm);
        }
        &::-webkit-progress-value{
            background-color: var(--color-primary);
        }
        &::-moz-progress-bar{
            background-color: var(--color-primary);
        }
    }

    .control--gigablock{
        & .control__content{
            width: 100%;
            padding: 24px 28px;
        }
        & .control__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            & > *{
                margin-bottom: 8px;
            }
            & .icon__wrapper{
                display: block;
                overflow: hidden;
                margin-right: 16px;
            }
            & .icon__vue{
                margin-top: -30px;
                margin-bottom: -30px;
            }
            & .icon__vue--gigachat{
                color: black;
            }
            & .multiselect{
                max-width: 312px;
                margin-left: auto;
            }
            & .control__llm{
                margin-left: 0;
                margin-right: 10px;
                width: auto;
                &.multiselect--disabled{
                    & .multiselect__tags{
                        background-color: transparent;
                    }
                }
                & .multiselect__tags{
                    padding: 0px 16px 0px 0px;
                    box-shadow: none !important;
                    border: none !important;
                }
                & .option__title{
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;
                    white-space: nowrap;
                    min-width: 250px;
                }
            }
            & .control__llm--hidden{
                & .multiselect__select{
                    display: none;
                }
            }
        }
        & .control__chat{
            max-height: 620px;
            overflow: auto;
            margin-bottom: 16px;
            padding-right: 8px;
            margin-right: -8px;
            max-width: none;
            width: auto;
            & .preloader{
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
        & .control__messages{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0;
        }
        & .control__message{
            max-width: 80%;
            display: flex;
            padding: 32px 16px;
            border-radius: calc( 2*var(--border-rad-lg) );
            background: color-mix(in srgb, var(--color-primary) 25%, transparent 75%);
            white-space: pre-line;
            &:not(:last-of-type){
                margin-bottom: 16px;
            }
        }
        & .message--gigablock{
            margin-left: auto;
            padding: 16px;
            background-color: var(--color-light2);
        }
        & .message__logo{
            flex-shrink: 0;
            margin-right: 16px;
            & .icon__vue--elementgigablockmin{
                width: 40px;
                height: 40px;
                margin: -5px;
                max-width: none;
            }
        }
        & .message__body{

        }
        & .message__actions{
            margin-left: 16px;
        }
        & .control__inputbox{

        }
        & .control__input{
            border: 1px solid;
            border-color: black;
            border-radius: calc( 2*var(--border-rad-lg) );
            margin-bottom: 12px;
            padding: 16px 12px;
            min-height: 160px;
            &:not(:disabled){
                &:hover{
                    border-color: black;
                }
            }
        }
        & .control__btns{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            & > *{
                margin-top: 5px;
                margin-bottom: 5px;
                &:not(:last-child){
                    margin-right: 16px;
                }
            }
        }
        & .control__aside{
            width: 100%;
            padding: 22px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-top: 2px solid;
            border-color: transparent;
            font-size: 14px;
            line-height: 1.4;
            white-space: pre-line;
            & p{
                margin-bottom: 1.1rem;
            }
            & .btn--link{
                margin-left: auto;
                text-decoration: underline;
            }
            &.control__aside--active{
                background-color: var(--color-light2);
                border-color: black;
                & .btn--link{
                    margin-left: 0;
                }
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            display: flex;
            & .control__content{
                padding-right: 32px;
            }
            & .control__btns{
                margin-bottom: 20px;
            }
            & .control__aside{
                max-width: 350px;
                border-top: none;
                border-left: 2px solid;
                border-color: transparent;
            }
        }
    }

    @keyframes note-editing {
        to {
            stroke-dashoffset: 1000;
        }
    }
}
