.tippy-tooltip {
	background-color: white;
	padding: 24px;
	font-size: 16px;
	font-weight: var(--fw-normal);
    box-shadow: var(--shadow-tippy);
    color: black;
    text-align: left;
    & p{
        &:last-child{
            margin-bottom: 0;
        }
    }
	.tippy-backdrop {
		background-color: white;
	}
	.tippy-roundarrow {
		fill: white;
	}
    &[x-placement^=top] {
        .tippy-arrow {
            border-top: 8px solid white;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    &[x-placement^=bottom] {
        .tippy-arrow {
            border-bottom: 8px solid white;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    &[x-placement^=left] {
        .tippy-arrow {
            border-left: 8px solid white;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
    &[x-placement^=right] {
        .tippy-arrow {
            border-right: 8px solid white;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
    &[data-animatefill] {
        background-color: white;
    }
    &[data-size=small] {
        font-size: 14px;
        padding: 6px 8px;
    }
    &[data-size=large] {
        font-size: 20px;
        padding: 18px;
    }
    &[data-size=long] {
        max-width: 600px !important;
    }
}
