@layer components {

    .menu{

    }

    .menu__item{

    }

    .menu__item--active{

    }

    .menu--profile{
        font-size: 14px;
        line-height: 1.143;
        & a{
            color: currentColor;
            text-decoration: none;
            flex-grow: 1;
        }
        & .menu__item--footer{
            margin-top: 12px;
            padding-top: 12px;
            border-top: 1px solid;
            border-color: color-mix(in srgb, white 16%, transparent 84%);
        }
        & .menu__item--active{
            .nav__item{
                background-color: var(--color-primary2);
            }
        }
        & .nav__item{
            display: flex;
            align-items: center;
            width: 100%;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 16px;
            padding-right: 16px;
            min-height: 32px;
            transition: background-color calc( var(--anim-dur)/2 );
            &:hover{
                background-color: var(--color-primary-hover);
            }
            &:active{
                background-color: color-mix(in srgb, var(--color-primary-hover) 100%, black 20%);
            }
            & .icon__wrapper{
                margin-right: 10px;
            }
            & .icon__vue{
                width: 24px;
                height: 24px;
            }
        }
    }

    .menu--constructor{
        & .menu{
            list-style: none;
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
            & .nav__item{
                padding-left: 35px;
                padding-right: 20px;
            }
        }
        & .menu__item{
            &:not(:last-of-type){
                border-bottom: 1px solid;
                border-color: var(--color-light1);
            }
            & .icon__wrapper{
                color: var(--color-light3);
                flex-shrink: 0;
            }
            &:hover{
                & > .nav__item{
                    cursor: pointer;
                    background-color: var(--color-light1);
                    & .icon__wrapper{
                        color: var(--color-primary);
                    }
                }
            }
        }
        & .menu__item--active{
            & > .nav__item{
                font-weight: var(--fw-medium);
                background-color: var(--color-light2) !important;
            }
        }
        & .menu__item--disabled{
            pointer-events: none;
            opacity: 0.5;
        }
        & .nav__item{
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
