.Vue-Toastification__container {
    --vt-toast-max-width: 400px;
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: var(--vt-toast-max-width);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-height: 100%;
    color: white;
    flex-direction: column;
    pointer-events: none;

    @media only screen and (min-width : 600px) {

        &.top-left,
        &.top-right,
        &.top-center {
            top: 1em;
        }

        &.bottom-left,
        &.bottom-right,
        &.bottom-center {
            bottom: 1.5em;
            flex-direction: column-reverse;
        }

        &.top-left,
        &.bottom-left {
            left: 1em;

            .Vue-Toastification__toast {
                margin-right: auto;
            }

            /* Firefox does not apply rtl rules to containers and margins, it appears.*/
            /* See https://github.com/Maronato/vue-toastification/issues/179*/
            @supports not (-moz-appearance:none) {
                .Vue-Toastification__toast--rtl {
                    margin-right: unset;
                    margin-left: auto;
                }
            }
        }

        &.top-right,
        &.bottom-right {
            right: 1em;

            .Vue-Toastification__toast {
                margin-left: auto;
            }

            /* Firefox does not apply rtl rules to containers and margins, it appears.*/
            /* See https://github.com/Maronato/vue-toastification/issues/179*/
            @supports not (-moz-appearance:none) {
                .Vue-Toastification__toast--rtl {
                    margin-left: unset;
                    margin-right: auto;
                }
            }
        }

        &.top-center,
        &.bottom-center {
            left: 50%;
            margin-left: calc( -1*var(--vt-toast-max-width)/2 );

            .Vue-Toastification__toast {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @media only screen and (max-width : 600px) {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0;

        .Vue-Toastification__toast {
            width: 100%;
        }

        &.top-left,
        &.top-right,
        &.top-center {
            top: 0;
        }

        &.bottom-left,
        &.bottom-right,
        &.bottom-center {
            bottom: 0;
            flex-direction: column-reverse;
        }
    }
}

.Vue-Toastification__toast {
    display: inline-flex;
    align-items: center;
    position: relative;
    max-height: 800px;
    min-height: 48px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 15px;
    border-radius: 0px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    justify-content: space-between;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    max-width: var(--vt-toast-max-width);
    min-width: 300px;
    pointer-events: auto;
    overflow: hidden;
    /* overflow: hidden + border-radius does not work properly on Safari*/
    /* The following magic line fixes it*/
    /* https://stackoverflow.com/a/58283449*/
    transform: translateZ(0);
    direction: ltr;

    @media only screen and (max-width : 600px) {
        border-radius: 0px;
        margin-bottom: 0.5rem;
    }

    &.disable-transition {
        transition: none !important;
        animation: none !important;
    }
}

.Vue-Toastification__toast--rtl {
    direction: rtl;
}

.Vue-Toastification__toast--default {
    background-color: #1976d2;
    color: white;
}

.Vue-Toastification__toast--info {
    background-color: #2196f3;
    color: white;
}

.Vue-Toastification__toast--success {
    background-color: #75B94E;
    color: white;
}

.Vue-Toastification__toast--error {
    background-color: #F56C6C;
    color: white;
}

.Vue-Toastification__toast--warning {
    background-color: #E6A23C;
    color: white;
}

.Vue-Toastification__toast-body {
    flex: 1;
    line-height: 1.3;
    font-size: 14px;
    word-break: break-word;
    white-space: pre-wrap;
}

.Vue-Toastification__toast-component-body {
    flex: 1;
}

.Vue-Toastification__toast{
    &.toast--lg {
        & .Vue-Toastification__toast-body {
            font-size: 28px;
        }
    }
}

.Vue-Toastification__close-button {
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    width: 24px;
    height: 24px;
    outline: none !important;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: 0.3s ease;
    align-items: center;
    color: white;
    opacity: 0.3;
    transition: visibility 0s, opacity 0.2s linear;
    position: absolute;
    top: 0;
    right: 0;

    &:hover,
    &:focus {
        opacity: 1;
    }

    .Vue-Toastification__toast:not(:hover) &.show-on-hover {
        opacity: 0;
    }

    .Vue-Toastification__toast--rtl & {
        padding-left: unset;
        padding-right: 10px;
    }
}

@keyframes scale-x-frames {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.Vue-Toastification__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.7);
    transform-origin: left;
    animation: scale-x-frames linear 1 forwards;

    .Vue-Toastification__toast--rtl & {
        right: 0;
        left: unset;
        transform-origin: right;
    }
}

.Vue-Toastification__icon {
    display: none;
    margin: auto 18px auto 0px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    transition: 0.3s ease;
    align-items: center;
    width: 20px;
    height: 100%;

    .Vue-Toastification__toast--rtl & {
        margin: auto 0px auto 18px;
    }
}

@keyframes bounceInRight {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes bounceOutRight {
    40% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(1000px, 0, 0);
    }
}

@keyframes bounceInLeft {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }

    75% {
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        transform: translate3d(5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: none;
    }
}

@keyframes bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}

.Vue-Toastification__bounce-enter-active {

    &.top-left,
    &.bottom-left {
        animation-name: bounceInLeft;
    }

    &.top-right,
    &.bottom-right {
        animation-name: bounceInRight;
    }

    &.top-center {
        animation-name: bounceInDown;
    }

    &.bottom-center {
        animation-name: bounceInUp;
    }
}

.Vue-Toastification__bounce-leave-active {

    &.top-left,
    &.bottom-left {
        animation-name: bounceOutLeft;
    }

    &.top-right,
    &.bottom-right {
        animation-name: bounceOutRight;
    }

    &.top-center {
        animation-name: bounceOutUp;
    }

    &.bottom-center {
        animation-name: bounceOutDown;
    }
}

.Vue-Toastification__bounce-move {
    transition-timing-function: ease-in-out;
    transition-property: all;
    transition-duration: 400ms;
}

/* ----------------------------------------------
 * Modified version from Animista
 * Animista is Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes fadeOutTop {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
}

@keyframes fadeOutLeft {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-50px);
        opacity: 0;
    }
}

@keyframes fadeOutBottom {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(50px);
        opacity: 0;
    }
}

@keyframes fadeOutRight {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(50px);
        opacity: 0;
    }
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInTop {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInBottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.Vue-Toastification__fade-enter-active {

    &.top-left,
    &.bottom-left {
        animation-name: fadeInLeft;
    }

    &.top-right,
    &.bottom-right {
        animation-name: fadeInRight;
    }

    &.top-center {
        animation-name: fadeInTop;
    }

    &.bottom-center {
        animation-name: fadeInBottom;
    }
}

.Vue-Toastification__fade-leave-active {

    &.top-left,
    &.bottom-left {
        animation-name: fadeOutLeft;
    }

    &.top-right,
    &.bottom-right {
        animation-name: fadeOutRight;
    }

    &.top-center {
        animation-name: fadeOutTop;
    }

    &.bottom-center {
        animation-name: fadeOutBottom;
    }
}

.Vue-Toastification__fade-move {
    transition-timing-function: ease-in-out;
    transition-property: all;
    transition-duration: 400ms;
}

/* ----------------------------------------------
 * Modified version from Animista
 * Animista is Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes slideInBlurredLeft {
    0% {
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slideInBlurredTop {
    0% {
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 0%;
        filter: blur(240px);
        opacity: 0;
    }

    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slideInBlurredRight {
    0% {
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 0% 50%;
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slideInBlurredBottom {
    0% {
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 100%;
        filter: blur(240px);
        opacity: 0;
    }

    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slideOutBlurredTop {
    0% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 0%;
        filter: blur(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        transform-origin: 50% 0%;
        filter: blur(240px);
        opacity: 0;
    }
}

@keyframes slideOutBlurredBottom {
    0% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }

    100% {
        transform: translateY(1000px) scaleY(2) scaleX(0.2);
        transform-origin: 50% 100%;
        filter: blur(240px);
        opacity: 0;
    }
}

@keyframes slideOutBlurredLeft {
    0% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-1000px) scaleX(2) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }
}

@keyframes slideOutBlurredRight {
    0% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }

    100% {
        transform: translateX(1000px) scaleX(2) scaleY(0.2);
        transform-origin: 0% 50%;
        filter: blur(40px);
        opacity: 0;
    }
}


.Vue-Toastification__slideBlurred-enter-active {

    &.top-left,
    &.bottom-left {
        animation-name: slideInBlurredLeft;
    }

    &.top-right,
    &.bottom-right {
        animation-name: slideInBlurredRight;
    }

    &.top-center {
        animation-name: slideInBlurredTop;
    }

    &.bottom-center {
        animation-name: slideInBlurredBottom;
    }
}

.Vue-Toastification__slideBlurred-leave-active {

    &.top-left,
    &.bottom-left {
        animation-name: slideOutBlurredLeft;
    }

    &.top-right,
    &.bottom-right {
        animation-name: slideOutBlurredRight;
    }

    &.top-center {
        animation-name: slideOutBlurredTop;
    }

    &.bottom-center {
        animation-name: slideOutBlurredBottom;
    }
}

.Vue-Toastification__slideBlurred-move {
    transition-timing-function: ease-in-out;
    transition-property: all;
    transition-duration: 400ms;
}
