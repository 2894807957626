@layer components {

    .tooltip{
        max-width: none;
        display: none;
        position: absolute;
        z-index: var(--z-index-tooltip);
    }

    .tooltip--visible{
        display: block;
    }

    .tooltip__content{
        padding: 10px;
        border-radius: var(--border-rad-lg);
        box-shadow: var(--shadow-medium);
        background-color: var(--color-light1);
    }

    .tooltip__parent{
        position: relative;
    }

    .tooltip--brightness{
        width: 100vw;
        max-width: 300px;
        top: calc(100% + 8px);
        & .tooltip__content{
            display: flex;
            align-items: center;
        }
        & .control--range{
            flex-grow: 1;
            margin-right: 8px;
        }
    }

}
