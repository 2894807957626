/* redefine standart css */
.vdp-datepicker__calendar {
    max-width: none;
	.cell {
		&:not(.blank) {
			&:not(.disabled).day {
				&:hover {
					border-color: #75B94E !important;
				}
			}
			&:not(.disabled).month {
				&:hover {
					border-color: #75B94E !important;
				}
			}
			&:not(.disabled).year {
				&:hover {
					border-color: #75B94E !important;
				}
			}
		}
	}
	.cell.selected {
        color: white !important;
		background: #75B94E !important;
		&:hover {
            color: white !important;
			background: #75B94E !important;
		}
	}
	.cell.selected.highlighted {
        color: white !important;
		background: #75B94E !important;
	}
}

.vdp-datepicker__clear-button{
	position: absolute;
	top: 50%;
	right: 6px;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	text-align: center;
	font-weight: 700;
	color: rgba(52, 51, 50, 0.8);
	&:hover{
		color: rgba(52, 51, 50, 1);
	}
	&::after{
		content: "×";
		font-size: 15px;
		vertical-align: middle;
	}
	& > span{
		display: none;
	}
}
