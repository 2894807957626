@layer components {

    .card{

    }

    .card__header{

    }

    .card__main{

    }

    .card__footer{

    }

    .card__wrapper{

    }

    .card--disabled{

    }

    .card--new{

    }

    .card__output{

    }


    .card--job{
        width: 100%;
        background-color: white;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 32px;
        padding-right: 32px;
        box-shadow: var(--shadow-small);
        transition: box-shadow var(--anim-dur);
        border-radius: var(--border-rad-lg);
        margin-bottom: 8px;
        min-height: 215px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        &:not(.card--disabled){
            &:hover{
                box-shadow: var(--shadow-big);
            }
        }
        &.card--job-blocked{
            background-color: color-mix(in srgb, var(--color-red) 2%, transparent 98%);
        }
        &.card--job-rise-in-price{
            &::after{
                content: '';
                display: block;
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: var(--color-primary);
                transition: width calc( var(--anim-dur) / 2);
            }
            &:not(.card--disabled){
                &:hover{
                    &::after{
                        width: 4px;
                    }
                }
            }
        }
        & .card__main{
            width: 100%;
            flex-grow: 1;
        }
        & .card__footer{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 15px;
            border-top: 1px solid;
            border-color: var(--color-light2);
        }
        & .job__wrapper{
            margin-right: 15px;
            & > .wrapper{
                max-width: 520px;
            }
        }
        & .job__title{
            font-size: 18px;
            font-weight: var(--fw-medium);
            text-decoration: none;
            margin-bottom: 15px;
            display: inline-flex;
            align-items: center;
            & .icon__wrapper{
                color: var(--color-grey3);
            }
        }
        & .job__caption{
            color: var(--color-grey1);
        }
        & .job__reward{
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            margin-bottom: 6px;
            flex-shrink: 0;
        }
        & .job__reward-value{
            display: flex;
            align-items: center;
            & strong{
                color: black;
                font-size: 24px;
                font-weight: var(--fw-medium);
            }
            & .icon__vue--riseinprice{
                margin-right: 8px;
            }
        }
        & .job__reward-condition{
            color: var(--color-grey4);
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .card__main{
                display: flex;
                justify-content: flex-start;
            }
            & .job__reward{
                align-items: flex-end;
                text-align: right;
                margin-left: auto;
                margin-bottom: 0;
                & > *{
                    margin-bottom: 0px;
                    &:not(:last-child){
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    .card--user{
        width: 100%;
        background-color: white;
        padding: 24px 14px;
        box-shadow: var(--shadow-small);
        transition: box-shadow var(--anim-dur);
        border-radius: var(--border-rad-lg);
        margin-bottom: 8px;
        min-height: 215px;
        display: flex;
        flex-direction: column;
        &:not(.card--disabled){
            &:hover{
                box-shadow: var(--shadow-big);
            }
        }
        & .card__main{
            width: 100%;
            flex-grow: 1;
        }
        & .card__footer{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 15px;
            border-top: 1px solid;
            border-color: var(--color-light2);
        }
        & .table--simple{
            & tbody{
                & tr{
                    &:hover{
                        background-color: var(--color-light1);
                    }
                }
            }
            & th,
            & td{
                &:last-of-type{
                    text-align: left;
                }
            }
        }
        &.card--new{
            --color: color-mix(in srgb, var(--color-primary) 5%, transparent 95%);
            background: var(--color) linear-gradient(180deg, color-mix(in srgb, var(--color-light1) 15%, var(--color) 85%) , var(--color) ) repeat-x;
            & tr{
                border-color: var(--color-light3);
            }
        }
        &.card--disabled{
            --color: color-mix(in srgb, var(--color-red) 5%, transparent 95%);
            background: var(--color) linear-gradient(180deg, color-mix(in srgb, var(--color-light1) 15%, var(--color) 85%), var(--color) ) repeat-x;
            & tr{
                border-color: var(--color-light3);
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {

        }
    }

    .card--task{
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - var(--header-min-height) - var(--main-py) ); /* мин. высота чтобы кнопки в футере всегда были прибиты к низу */
        & .page__wrapper{
            max-width: calc( var(--wrapper-header-max) + 2*var(--page-gap) );
        }
        & .card__header{
            margin-bottom: 20px;
            padding-top: 20px;
            & > .wrapper{
                width: 100%;
                border-bottom: 1px solid;
                border-color: var(--color-light3);
            }
        }
        & .card__main{
            flex-grow: 1;
        }
        & .card__footer{
            & > .wrapper{
                width: 100%;
                border-top: 1px solid;
                border-color: var(--color-light3);
                padding-top: 8px;
                padding-bottom: 8px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
        }
        & .card__output{
            margin-top: 20px;
            margin-bottom: 10px;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & .card__header{
                & > .wrapper{
                    display: flex;
                    justify-content: space-between;
                }
            }
            & .card__footer{
                position: sticky;
                bottom: 0;
                background-color: var(--color-light1);
                z-index: var(--z-index-card-task-footer);
            }
        }
    }

    .card--task-fullscreen{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--z-index-fullscreen);
        overflow: auto;
        background-color: var(--color-light1);
    }

    .card--task-moderator{
        & .card__output{
            display: flex;
            align-items: stretch;
            overflow: auto;
            position: relative;
            padding-bottom: 8px;
            @mixin scrollThin;
            & > .output{
                min-width: 33vw;
                margin-right: 0;
                margin-left: 0;
                gap: 0;
                &:first-child{
                    position: sticky;
                    left: 0;
                    z-index: 1;
                    background-color: color-mix(in srgb, var(--color-sprout) 90%, white 10%);
                    border: 1px solid;
                    border-color: black;
                }
                & > div{
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
            & .wrapper--output-row{
                margin-top: 5px;
                margin-bottom: 5px;
            }
            & .output__element--with-consensus{
                background-color: color-mix(in srgb, var(--color-sprout) 90%, white 10%);
            }
            & .output__element--without-consensus{
                background-color: color-mix(in srgb, var(--color-red) 50%, white 50%);
            }
        }
    }
}
