@layer components {

    .message{
        display: block;
        color: black;
        text-decoration: none;
        &:hover{
            color: black;
            text-decoration: none;
        }
    }

    .message__icon{
        flex-shrink: 0;
        & .icon__vue{
            transition: color 0s;
        }
    }

    .message__header{

    }

    .message__body{

    }

    .message__footer{

    }

    .message__type{

    }

    .message__project{

    }

    .message__title{

    }

    .message__author{

    }

    .message__date{
        color: var(--color-grey2);
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .message__actions{

    }

    .message__text{

    }

    .message--closed{

    }

    .message--new{

    }

    .message--self{

    }

    .message--service{

    }

}
