@layer components {
    .hr{
        height: 0;
        overflow: visible;
        margin-top: 15px;
        margin-bottom: 15px;
        border: 0;
        border-top: 1px solid;
        border-color: var(--color-light1);
    }

    .hr--light{
        border-color: var(--color-light3);
    }
}

