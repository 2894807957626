@layer components {

    .scroll{

    }

    .scroll--mobile-visible{
        @media only screen and (hover: none) and (pointer: coarse){
            padding-bottom: 12px !important;
            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            &::-webkit-scrollbar:vertical {
                width: 12px;
            }
            &::-webkit-scrollbar:horizontal {
                height: 12px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--color-grey4);
                border-radius: 10px;
                border: 2px solid white;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
        }
    }

    .scroll--thin{
        @mixin scrollThin;
    }
}
