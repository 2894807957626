@layer components {
    .alert{
        position:relative;
        padding:.75rem 1.25rem;
        margin-bottom:1rem;
        border:1px solid transparent;
        border-radius:.25rem
    }

    .alert--compact{
        display: inline-block;
        padding: 1px 5px;
        margin: 0;
        white-space: nowrap;
        font-size: 13px;
    }

    .alert--warning,
    .alert--danger,
    .alert--secondary,
    .alert--success,
    .alert--info,
    .alert--dark,
    .alert--primary,
    .alert--light{
        color: color-mix(in srgb, var(--base-color) 60%, black 40%);
        background-color: color-mix(in srgb, var(--base-color) 20%, white 80%);
        border-color: color-mix(in srgb, var(--base-color) 40%, white 60%);
    }

    .alert--warning{
        --base-color: var(--color-orange-dark);
    }

    .alert--danger{
        --base-color: var(--color-red);
    }

    .alert--secondary{
        --base-color: var(--color-grey3);
    }

    .alert--success{
        --base-color: var(--color-eucalyptus);
    }

    .alert--info{
        --base-color: var(--color-info);
    }

    .alert--dark{
        --base-color: var(--color-grey1);
    }

    .alert--primary{
        --base-color: var(--color-primary);
    }

    .alert--light{
        --base-color: var(--color-light1);
    }

}








