.border-green{
    border: 4px solid #75B94E;
}

.over-height{
    max-height: 500px;
    overflow-y: auto;
}

.i-table {
    border-collapse: collapse;
    border-spacing: 0;
}

.i-table td {
    padding: 1px 7px;
    border: 1px solid silver;
    min-width: 100px;
}

.i-table td:nth-child(1){
    white-space: nowrap;
}

.i-thead td {
    font-size: 0.7em;
    font-weight: bold;
}

.i-table tbody td{
    font-size: 0.9em;
}

.i-table .btn--sm{
    font-size: 0.8em;
    padding: 0.20rem;
}

.i-intent {
    color: var(--color-eucalyptus);
}

.ql-align-center{
    text-align: center;
}

.ql-align-justify{
    text-align: justify;
}

.ql-align-left{
    text-align: left;
}

.ql-align-right{
    text-align: right;
}
