
@layer components {

    .btn{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: var(--fw-normal);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        user-select: none;
        color: white;
        background-color: var(--color-primary);
        border: 1px solid;
        border-color: var(--color-primary);
        padding-top: 0.5625rem;
        padding-bottom: 0.5625rem;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
        border-radius: var(--border-rad-md);
        outline: none;
        cursor: pointer;
        box-shadow: var(--shadow-initial);
        transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out;
        &.btn--disabled,
        &:disabled{
            color: var(--color-sprout);
            background-color: var(--color-zanah);
            border-color: var(--color-zanah);
            cursor: context-menu;
            pointer-events: none;
        }
        &:hover{
            text-decoration: none;
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                color: white;
                background-color: var(--color-primary-hover);
                border-color: var(--color-primary-hover);
                box-shadow: var(--shadow-btn-hover);
            }
            &:focus{
                outline: none;
            }
            &:active{
                color: white;
                border-color: var(--color-primary2);
                background-color: var(--color-primary2);
                box-shadow: var(--shadow-initial);
            }
        }
        & > * + .icon__wrapper,
        & > .icon__wrapper + *{
            margin-left: 8px;
        }
        & > .icon__wrapper{
            height: 1em;
            display: flex;
            align-items: center;
            position: relative;
            &:not(:only-child){
                &:first-child{
                    margin-left: -8px;
                }
                &:last-child{
                    margin-right: -8px;
                }
            }
        }
    }

    .btn__text{

    }

    .btn--disabled{

    }

    .btn--primary{
        &.btn--loading{
            &:not(:disabled, .btn--disabled){
                &::after{
                    border-color: var(--color-light2);
                }
            }
        }
    }

    .btn--outline{
        color: var(--color-grey2);
        background-color: white;
        border-color: var(--color-light4);
        &.btn--disabled,
        &:disabled{
            background-color: white;
            border-color: var(--color-light2);
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                background-color: white;
                border-color: var(--color-primary-hover);
                color: var(--color-primary-hover);
                box-shadow: var(--shadow-btn-hover);
                &::before{
                    display: none;
                }
            }
            &:focus{
                &::before{
                    border-color: var(--color-primary2);
                }
            }
            &:active{
                border-color: var(--color-primary2);
                color: var(--color-primary2);
                background-color: white;
                box-shadow: var(--shadow-initial);
            }
        }
    }

    .btn--outline-primary{
        color: var(--color-primary);
        background-color: white;
        &.btn--disabled,
        &:disabled{
            background-color: white;
            border-color: var(--color-light2);
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                background-color: white;
                border-color: var(--color-primary-hover);
                color: var(--color-primary-hover);
                box-shadow: var(--shadow-btn-hover);
                &::before{
                    display: none;
                }
            }
            &:focus{
                &::before{
                    border-color: var(--color-primary2);
                }
            }
            &:active{
                border-color: var(--color-primary2);
                color: var(--color-primary2);
                background-color: white;
                box-shadow: var(--shadow-initial);
            }
        }
    }

    .btn--link{
        background-color: transparent;
        border-color: transparent;
        color: var(--color-primary);
        padding-left: 0;
        padding-right: 0;
        & .icon__wrapper{
            color: var(--color-primary);
        }
        &.btn--disabled,
        &:disabled{
            color: var(--color-light3);
            background-color: transparent;
            border-color: transparent;
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                background-color: transparent;
                border-color: transparent;
                color: var(--color-primary-hover);
                box-shadow: var(--shadow-initial);
            }
            &:focus{
                &::before{
                    display: none;
                }
            }
            &:active{
                border-color: transparent;
                color: var(--color-primary2);
                background-color: transparent;
                box-shadow: var(--shadow-initial);
            }
        }
        &.btn--sm{
            min-height: 1em;
            @mixin ieOnly {
                height: 1em;
            }
        }
    }

    .btn--block{
        width: 100%;
    }

    .btn--action{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 44px;
        min-height: 44px;
        padding: 0;
        border-radius: 0;
        color: var(--color-grey2);
        border: none !important;
        box-shadow: none !important;
        background: transparent !important;
        &.btn--disabled,
        &:disabled{
            color: var(--color-light3);
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                color: var(--color-primary);
            }
            &:active{
                color: var(--color-primary2);
            }
        }
        & > .icon__wrapper{
            height: auto;
            bottom: 0;
            margin-left: 0;
            pointer-events: none;
        }
        @mixin ieOnly {
            height: 44px;
        }
        &.btn--sm{
            min-width: 24px;
            min-height: 24px;
            @mixin ieOnly {
                height: 24px;
            }
        }
    }

    .btn--sm{
        font-size: 15px;
        min-height: var(--control-sm-height);
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
    }

    .btn--xm{
        font-size: 14px;
        min-height: var(--control-xm-height);
        padding-top: 0.27rem;
        padding-bottom: 0.27rem;
    }

    .btn--lg{
        font-size: 18px;
        padding-top: 0.719rem;
        padding-bottom: 0.719rem;
    }

    .btn--rounded{
        border-radius: 100px;
        &:not(:disabled){
            &:focus{
                &::before{
                    border-radius: 100px;
                }
            }
        }
    }

    .btn--warning{
        background-color: var(--color-orange);
        border-color: var(--color-orange);
        &.btn--disabled,
        &:disabled{
            color: var(--color-orange-dark);
            background-color: color-mix(in srgb, var(--color-orange) 50%, white 50%);
            border-color: color-mix(in srgb, var(--color-orange) 50%, white 50%);
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                background-color: color-mix(in srgb, var(--color-orange) 75%, white);
                border-color: color-mix(in srgb, var(--color-orange) 75%, white);
            }
            &:focus{
                &::before{
                    border-color: color-mix(in srgb, var(--color-orange) 75%, white);
                }
            }
            &:active{
                border-color: var(--color-orange-dark);
                background-color: var(--color-orange-dark);
            }
        }
        &.btn--outline{
            background-color: white;
            border-color: var(--color-orange);
            color: var(--color-orange);
            &:not(:disabled){
                &:hover,
                &:focus{
                    background-color: white;
                    border-color: color-mix(in srgb, var(--color-orange) 75%, white);
                    color: color-mix(in srgb, var(--color-orange) 75%, white);
                }
                &:focus{
                    &::before{
                        border-color: color-mix(in srgb, var(--color-orange) 75%, white);
                    }
                }
                &:active{
                    border-color: var(--color-orange-dark);
                    color: var(--color-orange-dark);
                    background-color: white;
                }
            }
        }
        &.btn--loading{
            &::after{
                border-color: var(--color-orange-dark);
            }
        }
    }

    .btn--danger{
        background-color: var(--color-red);
        border-color: var(--color-red);
        &.btn--disabled,
        &:disabled{
            color: color-mix(in srgb, var(--color-red) 80%, white 20%);
            background-color: color-mix(in srgb, var(--color-red) 40%, white 60%);
            border-color: color-mix(in srgb, var(--color-red) 40%, white 60%);
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                background-color: color-mix(in srgb, var(--color-red) 85%, white 15%);
                border-color: color-mix(in srgb, var(--color-red) 85%, white 15%);
            }
            &:focus{
                &::before{
                    border-color: color-mix(in srgb, var(--color-red) 85%, white 15%);
                }
            }
            &:active{
                border-color: color-mix(in srgb, var(--color-red) 80%, black 20%);
                background-color: color-mix(in srgb, var(--color-red) 80%, black 20%);
            }
        }
        &.btn--outline{
            background-color: white;
            border-color: var(--color-red);
            color: var(--color-red);
            &:not(:disabled){
                &:hover,
                &:focus{
                    background-color: white;
                    border-color: color-mix(in srgb, var(--color-red) 85%, white 15%);
                    color: color-mix(in srgb, var(--color-red) 85%, white 15%);
                }
                &:focus{
                    &::before{
                        border-color: color-mix(in srgb, var(--color-red) 85%, white 15%);
                    }
                }
                &:active{
                    border-color: color-mix(in srgb, var(--color-red) 80%, black 20%);
                    color: color-mix(in srgb, var(--color-red) 80%, black 20%);
                    background-color: white;
                }
            }
        }
        &.btn--loading{
            &::after{
                border-color: color-mix(in srgb, var(--color-red) 85%, white 15%);
            }
        }
    }

    .btn__group{
        display: flex;
        & .btn{
            &:not(:first-child){
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:not(:last-child){
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .btn--move{
        cursor: move;
    }

    .btn--th-sort{
        padding: 0;
        min-width: 0;
        min-height: 1em;
        text-align: left;
        font-size: 14px;
        color: var(--color-grey3);
    }

    .btn--sber{
        --color-sber-green: #21A038;
        --color-sber-gray: #767676;
        font-family: var(--font-sber);
        color: black;
        background-color: transparent;
        border: 1px solid;
        border-color: var(--color-sber-gray);
        border-radius: 4px;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 14px;
        font-weight: var(--fw-medium);
        & .icon__wrapper{
            color: var(--color-sber-green);
        }
        &.btn--disabled,
        &:disabled{
            background-color: transparent;
            color: var(--color-sber-gray);
            border-color: var(--color-light2);
            & .icon__wrapper{
                opacity: 0.5;
            }
        }
        &:not(:disabled){
            &:hover,
            &:focus{
                background-color: transparent;
                border-color: var(--color-sber-gray);
                color: black;
                box-shadow: var(--shadow-btn-hover);
                &::before{
                    display: none;
                }
            }
            &:focus{
                &::before{
                    border-color: var(--color-sber-gray);
                }
            }
            &:active{
                border-color: var(--color-sber-gray);
                color: black;
                background-color: transparent;
                box-shadow: var(--shadow-initial);
            }
        }
    }


    .btn--loading{
        position: relative;
        &::after{
            content: '';
            display: block;
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border-width: 0;
            border-style: solid;
            border-color: var(--color-primary-hover);
            border-radius: inherit;
            clip-path: rect(0% 0% 100% 0%);
            animation-name: btn-loading;
            animation-duration: calc(10*var(--anim-dur));
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }

    @keyframes btn-loading{
        0%{
            clip-path: rect(0% 0% 100% 0%);
            border-top-width: var(--btn-loading-border-w);
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
        }
        12%{
            clip-path: rect(0% 100% 100% 0%);
            border-top-width: var(--btn-loading-border-w);
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
        }
        24.9%{
            clip-path: rect(0% 100% 100% 100%);
            border-top-width: var(--btn-loading-border-w);
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
        }
        25%{
            clip-path: rect(0% 100% 0% 0%);
            border-top-width: 0;
            border-right-width: var(--btn-loading-border-w);
            border-bottom-width: 0;
            border-left-width: 0;
        }
        37%{
            clip-path: rect(0% 100% 100% 0%);
            border-top-width: 0;
            border-right-width: var(--btn-loading-border-w);
            border-bottom-width: 0;
            border-left-width: 0;
        }
        49.9%{
            clip-path: rect(100% 100% 100% 0%);
            border-top-width: 0;
            border-right-width: var(--btn-loading-border-w);
            border-bottom-width: 0;
            border-left-width: 0;
        }
        50%{
            clip-path: rect(0% 100% 100% 100%);
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: var(--btn-loading-border-w);
            border-left-width: 0;
        }
        62%{
            clip-path: rect(0% 100% 100% 0%);
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: var(--btn-loading-border-w);
            border-left-width: 0;
        }
        74.9%{
            clip-path: rect(0% 0% 100% 0%);
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: var(--btn-loading-border-w);
            border-left-width: 0;
        }
        75%{
            clip-path: rect(100% 100% 100% 0%);
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: var(--btn-loading-border-w);
        }
        87%{
            clip-path: rect(0% 100% 100% 0%);
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: var(--btn-loading-border-w);
        }
        100%{
            clip-path: rect(0% 100% 0% 0%);
            border-top-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: var(--btn-loading-border-w);
        }
    }
}
