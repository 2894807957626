@layer components {

    .user{

    }

    .user__progress{

    }

    .user__pic{
        width: var(--userpic-size);
        height: var(--userpic-size);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: white;
        border: 2px solid;
        border-color: var(--color-light4);
        flex-shrink: 0;
        position: relative;
        & > .wrapper{
            position: absolute;
            top: 150%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: top var(--anim-dur);
        }
        &:hover{
            & > .wrapper{
                top: 50%;
            }
        }
        &.user__pic--sm{
            width: calc( 0.625*var(--userpic-size) );
            height: calc( 0.625*var(--userpic-size) );
        }
    }

    .user__name{

    }

    .user__access{
        display: block;
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        cursor: pointer;
        background-color: var(--color-primary);
    }

    .user__location{

    }

    .user__balance{

    }

    .user--md{
        & .user__pic{
            width: var(--userpic-size);
            height: var(--userpic-size);
            margin-bottom: 12px;
        }
        & .user__name{
            font-size: 18px;
        }
        & .user__location{
            font-size: 12px;
        }
        & .user__balance{
            font-size: 14px;
            & .text--bold{
                font-size: 16px;
            }
        }
        @media (min-width: theme(--breakpoint-sm) ) {
            display: flex;
            align-items: center;
            & .user__name{
                @mixin cssLock font-size, theme(--breakpoint-sm), theme(--breakpoint-xl), 18, 28;
            }
            & .user__pic{
                margin-bottom: 0;
                margin-right: 12px;
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & .user__pic{
                width: calc( 1.5*var(--userpic-size) );
                height: calc( 1.5*var(--userpic-size) );
                margin-right: 18px;
            }
        }
        @media (min-width: theme(--breakpoint-xl) ) {
            & .user__name{
                font-size: 28px;
            }
        }
    }

    .user--lg{
        & .user__pic{
            width: calc( 1.5*var(--userpic-size) );
            height: calc( 1.5*var(--userpic-size) );
            margin-bottom: 12px;
        }
        & .user__name{
            font-size: 18px;
        }
        & .user__location{
            font-size: 12px;
        }
        & .user__balance{
            font-size: 14px;
            & .text--bold{
                font-size: 16px;
            }
        }
        @media (min-width: theme(--breakpoint-sm) ) {
            display: flex;
            align-items: center;
            & .user__name{
                @mixin cssLock font-size, theme(--breakpoint-sm), theme(--breakpoint-xl), 18, 32;
            }
            & .user__pic{
                margin-bottom: 0;
                margin-right: 12px;
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & .user__pic{
                width: calc( 2*var(--userpic-size) );
                height: calc( 2*var(--userpic-size) );
                margin-right: 24px;
            }
            & .user__location{
                font-size: 14px;
            }
        }
        @media (min-width: theme(--breakpoint-xl) ) {
            & .user__name{
                font-size: 32px;
            }
        }
    }
}
