@layer components {

    .nav{

    }

    .nav__item{

    }

    .nav--header{
        color: var(--color-grey2);
        display: none;
        & .menu{
            list-style: none;
            padding-left: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
        & .menu__item--ancestor{
            & > .nav__item{
                color: var(--color-primary);
            }
        }
        & > .menu{
            & .menu__item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                &.menu__item--active{
                    & > .nav__item{
                        color: black;
                    }
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        left: calc( -1*var(--page-gap) );
                        bottom: 0;
                        width: 6px;
                        height: 100%;
                        background-color: var(--color-primary);
                    }
                }
                & > .menu{
                    & .menu__item{
                        padding-left: 20px;
                    }
                }
            }
            & .nav__item{
                display: flex;
                align-items: center;
            }
            & .link{
                display: flex;
                align-items: center;
                height: 100%;
                text-decoration: none;
                padding-top: 12px;
                padding-bottom: 12px;
                & + *{
                    margin-left: 8px;
                }
            }
            & span.link{
                cursor: context-menu;
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            display: flex;
            margin-right: auto;
            padding-right: 10px;
            margin-top: -12px;
            margin-bottom: -12px;
            & > .menu{
                display: flex;
                flex-wrap: wrap;
                & .menu__item{
                    &:hover{
                        & > .menu{
                            display: flex;
                        }
                    }
                    &.menu__item--active{
                        &::after{
                            left: 0;
                            width: 100%;
                            height: 3px;
                        }
                    }
                    &:not(:last-of-type){
                        margin-right: 32px;
                    }
                    & > .menu{
                        display: none;
                        background-color: white;
                        box-shadow: var(--shadow-header);
                        flex-direction: column;
                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: var(--z-index-menu);
                        min-width: 100%;
                        max-width: 100vw;
                        white-space: nowrap;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        & .menu__item{
                            padding-left: 10px;
                            padding-right: 10px;
                            &::after{
                                width: 3px;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .nav--user{
        display: block;
        @media (min-width: theme(--breakpoint-lg) ) {
            display: none;
        }
    }
}
