@layer components {
    .info{

    }

    .info__value{
        display: flex;
        align-items: center;
        color: var(--color-grey1);
        & > * + .icon__wrapper,
        & > .icon__wrapper + *{
            margin-left: 8px;
        }
        & .icon__wrapper{
            color: var(--color-primary);
        }
    }

    .info__label{

    }

    .info--phone{
        & a {
            text-decoration: none;
        }
    }
}

