@layer components {

    .table_{/* пришлось добавить _ чтобы не конфликтовать с tailwind утилитой */
        width: 100%;
        font-size: 14px;
        & thead{
            font-size: 15px;
            font-weight: var(--fw-medium);
        }
        & th,
        & td{
            padding: 7px 9px;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            /*& th,
            & td{
                padding: 14px 18px;
            }*/
        }
    }

    .table__row{

    }

    .table__col{

    }

    .table__row--active{

    }

    .table__row--gray{
        background-color: var(--color-grey6) !important;
    }

    .table__wrapper{

    }

    .table--responsive{
        overflow-x: auto;
        display: inline-block;
        @media (min-width: theme(--breakpoint-md) ) {
            overflow-x: initial;
            display: table;
        }
    }

    .table--responsive-lg{
        overflow-x: auto;
        display: inline-block;
        @media (min-width: theme(--breakpoint-lg) ) {
            overflow-x: initial;
            display: table;
        }
    }

    .table--responsive-xl{
        overflow-x: auto;
        display: inline-block;
        @media (min-width: theme(--breakpoint-xl) ) {
            overflow-x: initial;
            display: table;
        }
    }

    .table--simple{
        & thead{
            & tr{
                border-bottom: 2px solid;
                border-color: var(--color-light4);
            }
        }
        & tbody{
            & tr{
                background-color: transparent;
                transition: background-color calc( var(--anim-dur)/2 );
                &:hover{
                    background-color: white;
                }
            }
        }
        & tr{
            border-bottom: 1px solid;
            border-color: var(--color-light2);
        }
        & th,
        & td{
            &:last-of-type{
                text-align: right;
            }
        }
    }

    .table--transparent{
        & thead{
            & tr{
                border-bottom: 2px solid;
                border-color: var(--color-light4);
            }
        }
        & tbody{
            & tr{
                background-color: transparent;
                transition: background-color calc( var(--anim-dur)/2 );
                &:hover{
                    background-color: white;
                }
            }
        }
        & tbody{
            tr:hover{
                background-color: transparent;
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & th,
            & td{
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
        }
    }

    .table--constructor{
        display: block;
        & > tbody,
        & > thead{
            display: block;
            & > tr {
                display: block;
            }
        }
        & th{
            display: block;
        }
        & td{
            display: block;
            padding-left: 0;
            padding-right: 0;
            padding-top: 4px;
            padding-bottom: 4px;
            &:first-of-type{
                font-size: 14px;
                color: var(--color-grey3);
            }
            &:last-of-type{
                padding-bottom: 20px;
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            display: table;
            & > tbody,
            & > thead{
                display: table-row-group;
                & > tr {
                    display: table-row;
                }
            }
            & th{
                display: table-cell;
            }
            & td{
                display: table-cell;
                vertical-align: center;
                padding: 8px;
                &:first-of-type{
                    color: black;
                    padding-left: 0;
                    width: 260px;
                }
                &:last-of-type{
                    padding-right: 0;
                    padding-bottom: 8px;
                }
            }
        }
    }

    .table--element{
        width: calc(100% - 56px);
        flex-grow: 1;
        font-size: 15px;
        & > thead{
            font-size: 18px;
        }
        & > thead,
        & > tbody{
            & > tr{
                & > th,
                & > td{
                    padding: 4px;
                    &:last-of-type{
                        padding-right: 16px;
                    }
                    &:nth-of-type(2){
                        padding-left: 24px;
                    }
                }
                & > th{
                    &:last-of-type{
                        text-align: right;
                        white-space: nowrap;
                        & * {
                            &:not(:last-child){
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }
    }

    .table--element-outer{
        & > thead,
        & > tbody{
            & > tr{
                & > th,
                & > td{
                    &:first-of-type{
                        color: var(--color-grey4);
                        width: var(--table-elem-first-col-w);
                        max-width: var(--table-elem-first-col-w);
                    }
                }
            }
        }
    }

    .table--element-inner{
        & > thead{
            font-size: 15px;
            font-weight: var(--fw-normal);
            & th:first-of-type{
                width: 20px;
            }
        }
        & > thead,
        & > tbody{
            & > tr{
                & > th,
                & > td{
                    &:first-of-type{
                        padding-left: 24px;
                    }
                }
            }
        }
    }

    .table--projects{
        & > thead{
            & .multiselect{
                display: inline-block;
                width: auto;
                min-width: 250px;
            }
            & > tr{
                border-bottom: 2px solid;
                border-color: var(--color-grey4);
                & > th{
                    font-weight: var(--fw-normal);
                    font-size: 14px;
                    color: var(--color-grey3);
                }
            }
        }
        & th,
        & td{
            padding: 9px 16px;
            &:last-of-type{
                text-align: right;
            }
        }
        & > tbody{
            & > tr:not(.project__pools){
                & > td{
                    &:last-of-type{
                        padding-right: 8px;
                    }
                }
            }
        }
        & .project{
            border-bottom: 1px solid;
            border-color: var(--color-grey4);
            background-color: transparent;
            transition: background-color calc( var(--anim-dur)/2 );
            &.project--published{
                background-color: color-mix(in srgb, var(--color-primary) 20%, transparent 80%);
                &:hover{
                    background-color: color-mix(in srgb, var(--color-primary) 10%, transparent 90%);
                }
            }
            &:hover{
                background-color: white;
            }
        }
        & .project__title{
            font-weight: var(--fw-bold);
            font-size: 16px;
            min-width: 12em;
            display: inline-block;
            cursor: pointer;
        }
        & .project__comment{
            min-width: 10em;
        }
        & .project__tags{
            & > .wrapper{
                display: flex;
                align-items: center;
            }
        }
        & .project__actions{
            display: inline-flex;
            align-items: center;
            & .icon__vue{
                transition: tarnsform calc( var(--anim-dur)/2 );
            }
            & > * {
                &:not(:last-child){
                    margin-right: 24px;
                }
            }
        }
        & .project__pools{
            display: none;
            border-bottom: 1px solid;
            border-color: var(--color-grey4);
            & > td{
                padding: 8px 0 24px;
                text-align: left;
            }
            & .table__wrapper{
                background-color: white;
                box-shadow: var(--shadow-btn-hover);
                border-radius: calc( 2*var(--border-rad-lg) );
                width: 100%;
                padding: 0 16px 16px;
            }
        }
        & .table__row--active{
            border-color: transparent;
            &:hover{
                background-color: transparent;
            }
            & .project__actions{
                & > .btn--action{
                    & .icon__vue--down{
                        transform: rotate(180deg);
                    }
                }
            }
            & + .project__pools{
                display: table-row;
            }
        }
    }

    .table--payments{
        white-space: pre-wrap;
        overflow-x: auto;
        display: inline-block;
        & thead{
            & tr{
                border-bottom: 2px solid;
                border-color: var(--color-light4);
            }
        }
        & tbody{
            & tr{
                background-color: transparent;
                transition: background-color calc( var(--anim-dur)/2 );
                &:hover{
                    background-color: white;
                }
            }
        }
        & tr{
            border-bottom: 1px solid;
            border-color: var(--color-light2);
        }
        & th,
        & td{
            &:last-of-type{
                text-align: right;
            }
        }
        & td{
            min-width: 10em;
        }
    }

    .table--project-items{
        font-size: 15px;
        margin-bottom: 16px;
        & > thead{
            font-size: 14px;
            white-space: nowrap;
            & tr{
                border-bottom: 1px solid;
                border-color: var(--color-light3);
                & th{
                    padding: 14px 16px 5px;
                    font-weight: var(--fw-normal);
                    color: var(--color-grey3);
                    &:last-of-type{
                        text-align: right;
                    }
                }
            }
        }
        & > tbody{
            & > tr{
                border-bottom: 1px solid;
                border-color: var(--color-light3);
            }
            & td{
                padding: 2px 16px;
                &:last-of-type{
                    text-align: right;
                }
            }
        }
        & .table__col--id,
        & .table__col--priority{
            white-space: nowrap;
        }
        & .table__col--name{
            min-width: 300px;
        }
        & .table__col--date{
            white-space: nowrap;
        }
        & .table__col--status{

        }
        & .table__col--pool-status{
            min-width: 138px;
        }
        & .table__col--actions{

        }
    }

    .table--users{
        & > tbody{
            & td{
                padding: 8px 16px;
                min-width: 300px;
            }
        }
        & .table__col--label{
            font-size: 15px;
            white-space: nowrap;
            padding: 8px 16px 8px 0px;
            font-weight: var(--fw-normal);
            color: var(--color-grey3);
            min-width: 0;
        }
        & .user__pic{
            margin-right: 10px;
            width: 30px;
            height: 30px;
        }
    }

    .table--csv{
        border: 1px solid;
        border-color: var(--color-light4);
        & thead{
            white-space: nowrap;
        }
        & tbody{
            & tr{
                background-color: transparent;
                transition: background-color calc( var(--anim-dur)/2 );
                &:hover{
                    background-color: white;
                }
            }
        }
        & tr{
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-color: var(--color-light4);
        }
        & th,
        & td{
            border-left: 1px solid;
            border-right: 1px solid;
            border-color: var(--color-light4);
        }
        & th{
            width: 1%;
        }
    }
}
