@layer components {

    .text{

    }

    .text--bold{
        font-weight: var(--fw-bold);
    }

    .text--medium{
        font-weight: var(--fw-medium);
    }

    .text--normal{
        font-weight: var(--fw-normal);
    }

    .text--primary{
        color: var(--color-primary);
    }

    .text--accented{
        font-size: 18px;
        font-weight: var(--fw-medium);
    }

    .text--highlighted{

    }

    .text--right{
        text-align: right;
    }

    .text--left{
        text-align: left;
    }

    .text--center{
        text-align: center;
    }

    .text--red{
        color: var(--color-red);
    }

    .text--orange{
        color: var(--color-orange);
    }

    .text--orange-dark{
        color: var(--color-orange-dark);
    }

    .text--muted{
        color: var(--color-grey2);
    }

    .text--grey{
        color: var(--color-grey3);
    }

    .text--black{
        color: black;
    }

    .text--light{
        color: var(--color-grey4);
    }

    .text--info{
        color: var(--color-info);
    }

    .text--success{
        color: var(--color-eucalyptus);
    }

    .text--small{
        font-size: 13px;
    }

    .text--pre-wrap{
        white-space: pre-wrap;
    }

    .text--pre{
        white-space: pre;
    }

    .text--pre-line{
        white-space: pre-line;
    }

    .text--wrap{
        white-space: normal;
    }

    .text--underlined{
        text-decoration: underline;
    }

    .text--line-through{
        text-decoration: line-through;
    }

    .text--lh-1{
        line-height: 1;
    }
}
