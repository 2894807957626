@layer components {

    .wrapper{

    }

    .wrapper__content{

    }

    .wrapper__before{

    }

    .wrapper__after{

    }

    .wrapper--visible{

    }

    .wrapper--controls{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        & .btn{
            min-width: 32px;
            min-height: 32px;
            padding: 0;
            border-color: var(--color-grey4);
        }
        & .control--select{
            & > .control__input{
                border-color: var(--color-grey4);
            }
        }
    }

    .wrapper--drop-over{

    }

    .wrapper--drop-before{

    }

    .wrapper--drop-after{

    }

    .wrapper--message-type{

    }

    .wrapper--profile{
        width: 100%;
        margin-bottom: 16px;
        & > *{
            flex-grow: 1;
            margin-bottom: 16px;
        }
        & .user{
            margin-right: 32px;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            display: flex;
            justify-content: space-between;
        }
    }

    .wrapper--numbergroup{
        width: 100%;
        max-width: 120px;
    }

    .wrapper--white-box{
        background-color: white;
        box-shadow: var(--shadow-medium);
        border-radius: var(--border-rad-lg);
        overflow: hidden;
        margin-bottom: 30px;
    }

    .wrapper--output-row{
        width: 100%;
        padding: 15px;
        background-color: white;
        border: 1px solid;
        border-color: var(--color-light3);
        border-radius: var(--border-rad-lg);
        //overflow: auto;
        & .control--video,
        & .control--audio{
            width: 100%;
            & .control__content{
                overflow: auto;
            }
        }
        & .wrapper--output-row__title{

        }
        & .wrapper--output-row__description{

        }
        @media (min-width: theme(--breakpoint-md) ) {
            & .control--video,
            & .control--audio{
                & .control__content{
                    max-width: 75%;
                }
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .control--video,
            & .control--audio{
                & .control__content{
                    max-width: 50%;
                }
            }
        }
    }

    .wrapper--output-row-premarkup{
        border: 4px solid var(--color-primary);
    }

    .wrapper--output-row-fullscreen{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--z-index-fullscreen);
        overflow: auto;
    }

    .wrapper--output-row-gigablock{
        padding: 0;
        border: 2px solid;
        border-color: black;
        & .wrapper--output-row__title,
        & .wrapper--output-row__description{
            display: none !important;
        }
    }

    .wrapper--output-row-interval{
        padding: 0;
        border: none;
        background-color: transparent;
        & .wrapper--output-row__title,
        & .wrapper--output-row__description{
            display: none !important;
        }
    }

    .wrapper--light-box{
        background-color: var(--color-light1);
        box-shadow: var(--shadow-small);
        border-radius: var(--border-rad-lg);
        overflow: hidden;
        margin-bottom: 30px;
    }

    .wrapper--example{
        width: 100%;
        position: relative;
        padding: 24px;
        padding-right: 48px;
        border-radius: var(--border-rad-lg);
        margin-bottom: 16px;
        & .icon__wrapper{
            position: absolute;
            top: 16px;
            right: 16px;
            opacity: 0.5;
            pointer-events: none;
        }
        & .icon__vue{
            width: 32px;
            height: 32px;
        }
    }

    .wrapper--example-sucess{
        background-color: color-mix(in srgb, var(--color-primary) 8%, transparent 92%);
        & .icon__wrapper{
            color: var(--color-primary);
        }
    }

    .wrapper--example-error{
        background-color: color-mix(in srgb, var(--color-red) 8%, transparent 92%);
        & .icon__wrapper{
            color: var(--color-red);
        }
    }

    .wrapper--example-warning{
        background-color: color-mix(in srgb, var(--color-orange) 8%, transparent 92%);
        & .icon__wrapper{
            color: var(--color-orange);
        }
    }

    .wrapper--collapsed-text{
        max-height: 8em;
        overflow-y: auto;
        padding-right: 4px;
    }

    .wrapper--code{
        background-color: white;
        border-radius: var(--border-rad-sm);
        border: 1px solid;
        border-color: var(--color-light3);
        display: flex;
        padding: 8px 12px;
        padding-right: 2px;
        margin-bottom: 16px;
        & > .wrapper{
            font-size: 14px;
            white-space: nowrap;
            color: black;
        }
        & .wrapper__content{
            position: relative;
            padding-left: 8px;
            padding-right: 10px;
            flex-grow: 1;
            max-height: 300px;
            resize: vertical;
            overflow-x: auto;
            &[style*="height"]{
                max-height: unset;
            }
            & pre,
            & code,
            & textarea{
                min-height: 70px;
                margin: 0;
                padding: 0;
                font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                font-size: 1em;
                outline: none !important;
            }
            & pre,
            & code{
                white-space: pre-wrap;
            }
            & textarea{
                padding-left: 8px;
                padding-right: 10px;
                border: none;
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                width: calc(100% - 15px);
                height: 100%;
                overflow: hidden;
                resize: none;
                opacity: 0;
                &:focus{
                    opacity: 1;
                }
            }
        }
    }

    .wrapper--element{
        display: flex;
        box-shadow: var(--shadow-footer-front);
        border-radius: calc( 2*var(--border-rad-lg) );
        margin-bottom: 14px;
        background-color: white;
        padding: 12px 8px;
        border: 1px solid;
        border-color: transparent;
        position: relative;
        &.wrapper--drop-over{
            border-color: var(--color-primary-hover);
            border-style: dashed;
        }
        &.wrapper--drop-before{
            & > .wrapper__before{
                border-color: var(--color-primary-hover);
                border-style: dashed;
            }
        }
        &.wrapper--drop-after{
            & > .wrapper__after{
                border-color: var(--color-primary-hover);
                border-style: dashed;
            }
        }
        & > .wrapper__content{
            display: flex;
            flex-direction: column;
            background-color: var(--color-light1);
            border-top-right-radius: calc( 2*var(--border-rad-lg) );
            border-bottom-right-radius: calc( 2*var(--border-rad-lg) );
            padding: 0 6px;
            margin-top: -12px;
            margin-bottom: -12px;
            margin-right: -8px;
        }
        & > .wrapper__before,
        & > .wrapper__after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 0;
            border-radius: var(--border-rad-lg);
            border: 1px solid;
            border-color: transparent;
        }
        & > .wrapper__before{
            top: 0;
            transform: translateY(calc(-100% - 1px));
        }
        & > .wrapper__after{
            bottom: 0;
            transform: translateY(calc(100% + 1px));
        }
    }

    .wrapper--element-outer{
        background: linear-gradient(to bottom, var(--color-light2) 0%,var(--color-light2) 100%);
        background-repeat: no-repeat;
        background-size: 1px 100%;
        background-position: calc(var(--table-elem-first-col-w) + 8px) 0;
    }

    .wrapper--element-outer-active{
        &.wrapper--drop-over,
        & > .wrapper--drop-before,
        & > .wrapper--drop-after{
            border-color: transparent !important;
        }
    }

    .wrapper--element-inner{
        background-color: var(--color-light1);
        & .wrapper__content{
            border-left: 1px solid;
            border-color: var(--color-light2);
        }
    }

    .wrapper--nojobs{
        width: 100%;
        & > .wrapper{
            margin-bottom: 15px;
            &:first-child{
                width: 100%;
                max-width: 500px;
            }
            & svg{
                height: auto;
            }
        }
        & .text--muted{
            font-size: 16px;
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 30px;
            & > .wrapper{
                margin-bottom: 0;
                &:first-child{
                    max-width: 425px;
                    margin-right: 50px;
                }
            }
            & .text--muted{
                font-size: 20px;
            }
        }
    }

    .wrapper--timescale{

    }

    .wrapper--track{

    }

    .wrapper--video{

    }

    .wrapper--notes{

    }

    .wrapper--notes-table{

    }

    .wrapper--control-counter{
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
        pointer-events: none;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        & > div{
            display: flex;
            position: relative;
        }
        & .wrapper__before{
            white-space: nowrap;
            flex-shrink: 0;
            line-height: 1.6;
            font-size: 14px;
            color: var(--color-grey4);
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: calc( 100% + 12px );
            font-family: monospace;
        }
        & .wrapper__content{
            white-space: pre-wrap;
            opacity: 0;
        }
    }

    .wrapper--tab-content{
        padding: 20px 15px;
        width: 100%;
        background-color: white;
        border: 1px solid;
        border-color: var(--color-light3);
        border-radius: var(--border-rad-lg);
        & > .control--collapse,
        & > * > .control--collapse{
            padding-bottom: 20px;
            border-bottom: 2px solid;
            border-color: var(--color-light3);
            margin-bottom: 20px;
            & > .control__content{
                margin-bottom: 10px;
            }
        }
    }

    .wrapper--user{
        padding: 10px 15px;
        width: 100%;
        background-color: white;
        border: 1px solid;
        border-color: var(--color-light3);
        border-radius: var(--border-rad-lg);
        margin-bottom: 20px;
        & .table--users{
            & > tbody{
                & > tr{
                    border-bottom: 1px solid;
                    border-color: var(--color-light3);
                }
            }
        }
    }

    .wrapper--sticky-top{
        margin-bottom: 16px;
        @supports (position: sticky) {
            position: sticky;
            top: 0;
            z-index: 2;
        }
    }

    .wrapper--hotkeys-info{
        display: inline-block;
        & .btn--action{
            color: var(--color-grey4);
        }
    }

    .wrapper--output-checkbox-radio{
        display: flex;
        align-items: flex-start;
        .wrapper--hotkeys-info{
            margin-right: 0.5rem;
            & .alert--compact{
                min-width: 1.7em;
                text-align: center;
            }
        }
    }

    .wrapper--datetime{
        display: flex;
        & .control--date{
            min-width: 110px;
            flex-grow: 1;
            margin-right: 8px;
        }
        & .control--time{
            min-width: 88px;
            flex-grow: 1;
        }
    }

    .wrapper--subheader{
        margin-top: calc( -1*var(--main-py) - var(--header-mb) );
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .wrapper--table{
        overflow-x: auto;
    }

    .wrapper--media-error{
        display: block;
        width: 100%;
        position: relative;
        z-index: calc( var(--z-index-overlap) + 1 );
        & .wrapper__content{
            display: flex;
            align-items: center;
        }
        & .alert{
            display: inline-block;
            text-decoration: none !important;
            color: color-mix(in srgb, var(--color-red) 57.71%, black 42.29%) !important;
            margin-bottom: 0;
        }
    }

    .wrapper--login{
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        & > .control--tabs{
            background-color: white;
            box-shadow: var(--shadow-medium);
            border-radius: var(--border-rad-lg);
            overflow: hidden;
            padding: 20px 10px 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        & .control__content{
            width: 100%;
            max-width: 400px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: 10px;
        }
    }

    .wrapper--login-separator{
        width: 100%;
        text-align: center;
        color: var(--color-grey3);
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 8px;
        &::before,
        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color-grey3);
        }
        & .text{
            flex-shrink: 0;
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    .wrapper--snippets{
        & .wrapper__before{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 8px;
            & .multiselect{
                width: auto;
                white-space: nowrap;
            }
            & .multiselect__content-wrapper{
                min-width: 140px;
                left: auto;
                right: 0;
            }
        }
        & .wrapper__content{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            border-top: 1px solid;
            border-color: var(--color-light2);
        }
        & .snippet{
            &:not(:last-child){
                border-right: 1px solid;
                border-color: var(--color-light2);
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & .wrapper__content{
                grid-template-columns: repeat(2, 1fr);
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .wrapper__content{
                grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                grid-template-rows: repeat(1, auto);
            }
        }
    }

    .wrapper--counter{
        white-space: nowrap;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        & > *{
            &:first-child{
                text-align: right;
            }
            &:last-child{
                text-align: left;
                &::before{
                    content: '/';
                    display: inline-block;
                }
            }
        }
    }

    .wrapper--input-icon{
        position: relative;
        & > .control__input{
            padding-right: 40px;
        }
        & > .btn{
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
        }
    }

    .wrapper--external-auth{
        & .icon__vue--lock{
            max-width: 16px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
