.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6{
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 1.5;
	font-family: Roboto, Arial, Helvetica, sans-serif;
	font-weight: var(--fw-normal);
}

.h1,
h1{
	font-size: 24px;
    @media (min-width: theme(--breakpoint-sm) ) {
        @mixin cssLock font-size, theme(--breakpoint-sm), theme(--breakpoint-lg), 24, 32;
    }
    @media (min-width: theme(--breakpoint-lg) ) {
        font-size: 32px;
    }
}

.h2,
h2{
	font-weight: var(--fw-medium);
	font-size: 22px;
	@media (min-width: theme(--breakpoint-sm) ) {
        @mixin cssLock font-size, theme(--breakpoint-sm), theme(--breakpoint-lg), 18, 24;
	}
	@media (min-width: theme(--breakpoint-lg) ) {
		font-size: 24px;
	}
}

.h3,
h3{
	font-size: 22px;
	@media (min-width: theme(--breakpoint-sm) ) {
        @mixin cssLock font-size, theme(--breakpoint-sm), theme(--breakpoint-lg), 18, 24;
	}
	@media (min-width: theme(--breakpoint-lg) ) {
		font-size: 24px;
	}
}

.h4,
h4{
	font-weight: var(--fw-medium);
	font-size: 18px;
}

.h5,
h5{
	font-size: 16px;
	font-weight: var(--fw-bold);
}

.h6,
h6{

}
