@layer components {

    .preloader{

    }

    .preloader__icon{

    }

    .preloader__caption{

    }

    .preloader--sm{

    }

    .preloader--circle{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px auto;
        color: var(--color-primary);
        & .preloader__icon{
            margin-right: 15px;
            & .icon__vue{
                width: 38px;
                height: 38px;
            }
        }
        & .preloader__caption{
            font-size: 15px;
        }
        &.preloader--sm{
            & .icon__vue{
                width: 24px;
                height: 24px;
            }
        }
    }

    .preloader--task{
        --skin: #f1c5b4;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .preloader__icon {
            display: flex;
            overflow: hidden;
        }

        & .preloader__caption{
            margin-top: 20px;
            font-size: 20px;
            text-align: center;
        }

        /** Wizard CSS & Animations */
        & .wizard {
            position: relative;
            width: 190px;
            height: 240px;
            margin-right: 10px;
        }

        & .wizard__body {
            position: absolute;
            bottom: 0;
            left: 68px;
            height: 100px;
            width: 60px;
            background: var(--color-primary);
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 20px;
                height: 100px;
                width: 60px;
                background: var(--color-primary);
                transform: skewX(14deg);
            }
        }

        & .wizard__right-arm {
            position: absolute;
            bottom: 74px;
            left: 110px;
            height: 44px;
            width: 90px;
            background: var(--color-primary);
            border-radius: 22px;
            /** animation */
            transform-origin: 16px 22px;
            transform: rotate(70deg);
            animation: right_arm 10s ease-in-out infinite;
        }

        & .wizard__right-hand {
            position: absolute;
            right: 8px;
            bottom: 8px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: var(--skin);
            /** animation */
            transform-origin: center center;
            transform: rotate(-40deg);
            animation: right_hand 10s ease-in-out infinite;
            &::after {
                content: '';
                position: absolute;
                right: 0px;
                top: -8px;
                width: 15px;
                height: 30px;
                border-radius: 10px;
                background: var(--skin);
                /** Animation */
                transform: translateY(16px);
                animation: right_finger 10s ease-in-out infinite;
            }
        }

        & .wizard__left-arm {
            position: absolute;
            bottom: 74px;
            left: 26px;
            height: 44px;
            width: 70px;
            background: var(--color-primary);
            border-bottom-left-radius: 8px;
            /** animation */
            transform-origin: 60px 26px;
            transform: rotate(-70deg);
            animation: left_arm 10s ease-in-out infinite;
        }

        & .wizard__left-hand {
            position: absolute;
            left: -18px;
            top: 0;
            width: 18px;
            height: 30px;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
            background: var(--skin);
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 15px;
                border-radius: 20px;
                background: var(--skin);
                /** Animation */
                transform-origin: right bottom;
                transform: scaleX(0);
                animation: left_finger 10s ease-in-out infinite;
            }
        }

        & .wizard__head {
            position: absolute;
            top: 0;
            left: 14px;
            width: 160px;
            height: 210px;
            /** Animation */
            transform-origin: center center;
            transform: rotate(-3deg);
            animation: head 10s ease-in-out infinite;
        }

        & .wizard__beard {
            position: absolute;
            bottom: 0;
            left: 38px;
            height: 106px;
            width: 80px;
            border-bottom-right-radius: 55%;
            background: white;
            &::after {
                content: "";
                position: absolute;
                top: 16px;
                left: -10px;
                width: 40px;
                height: 20px;
                border-radius: 20px;
                background: white;
            }
        }

        & .wizard__face {
            position: absolute;
            bottom: 76px;
            left: 38px;
            height: 30px;
            width: 60px;
            background: var(--skin);
            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: 40px;
                width: 20px;
                height: 40px;
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
                background: var(--skin);
            }
            &::after {
                content: '';
                position: absolute;
                top: 16px;
                left: -10px;
                width: 50px;
                height: 20px;
                border-radius: 20px;
                border-bottom-right-radius: 0px;
                background: white;
            }
        }

        & .wizard__adds {
            /** Nose */
            position: absolute;
            top: 0px;
            left: -10px;
            width: 40px;
            height: 20px;
            border-radius: 20px;
            background: var(--skin);
            &::after {
                /** Ear */
                content: "";
                position: absolute;
                top: 5px;
                left: 80px;
                width: 15px;
                height: 20px;
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
                background: var(--skin);
            }
        }

        .wizard__eyes{
            position: absolute;
            top: 2px;
            left: 10px;
            width: 30px;
            height: 6px;
            &::before,
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: black;
            }
            &::before{
                left: 0;
            }
            &::after{
                right: 0;
            }
        }

        & .wizard__hat {
            position: absolute;
            bottom: 106px;
            left: 0;
            width: 160px;
            height: 20px;
            border-radius: 20px;
            background: var(--color-primary);
            &::before {
                content: '';
                position: absolute;
                top: -70px;
                left: 50%;
                transform: translatex(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 34px 70px 50px;
                border-color: transparent transparent var(--color-primary) transparent;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 160px;
                height: 20px;
                background: var(--color-primary);
                border-radius: 20px;
            }
        }

        & .list--objects {
            position: relative;
            width: 200px;
            height: 240px;
            left: 40px;
            list-style: none;
            padding: 0;
            margin: 0;
            & .list__item{
                position: absolute;
                border-radius: 50%;
                background: transparent;
                transform-origin: center center;
                transform: translate(0, 0) rotate(0deg);
                & svg{
                    fill: currentColor;
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    left: 20px;
                    bottom: 0;
                }
                &:nth-of-type(1){
                    width: 150px;
                    height: 150px;
                    top: 90px;
                    left: -20px;
                    animation: object-1 10s ease-in infinite;
                }
                &:nth-of-type(2){
                    width: 100px;
                    height: 100px;
                    top: 140px;
                    left: 20px;
                    animation: object-2 10s ease-in infinite;
                }
                &:nth-of-type(3){
                    width: 50px;
                    height: 50px;
                    top: 190px;
                    left: 55px;
                    animation: object-3 10s ease-in infinite;
                }
            }
        }
    }

    @keyframes right_arm {
        0% {
            transform: rotate(70deg);
        }
        10% {
            transform: rotate(8deg);
        }
        15% {
            transform: rotate(20deg);
        }
        20% {
            transform: rotate(10deg);
        }
        25% {
            transform: rotate(26deg);
        }
        30% {
            transform: rotate(10deg);
        }
        35% {
            transform: rotate(28deg);
        }
        40% {
            transform: rotate(9deg);
        }
        45% {
            transform: rotate(28deg);
        }
        50% {
            transform: rotate(8deg);
        }
        58% {
            transform: rotate(74deg);
        }
        62% {
            transform: rotate(70deg);
        }
    }

    @keyframes left_arm {
        0% {
            transform: rotate(-70deg);
        }
        10% {
            transform: rotate(6deg);
        }
        15% {
            transform: rotate(-18deg);
        }
        20% {
            transform: rotate(5deg);
        }
        25% {
            transform: rotate(-18deg);
        }
        30% {
            transform: rotate(5deg);
        }
        35% {
            transform: rotate(-17deg);
        }
        40% {
            transform: rotate(5deg);
        }
        45% {
            transform: rotate(-18deg);
        }
        50% {
            transform: rotate(6deg);
        }
        58% {
            transform: rotate(-74deg);
        }
        62% {
            transform: rotate(-70deg);
        }
    }

    @keyframes right_hand {
        0% {
            transform: rotate(-40deg);
        }
        10% {
            transform: rotate(-20deg);
        }
        15% {
            transform: rotate(-5deg);
        }
        20% {
            transform: rotate(-60deg);
        }
        25% {
            transform: rotate(-0deg);
        }
        30% {
            transform: rotate(-60deg);
        }
        35% {
            transform: rotate(-0deg);
        }
        40% {
            transform: rotate(-40deg);
        }
        45% {
            transform: rotate(-60deg);
        }
        50% {
            transform: rotate(10deg);
        }
        60% {
            transform: rotate(-40deg);
        }
    }

    @keyframes right_finger {
        0% {
            transform: translateY(16px);
        }
        10% {
            transform: none;
        }
        50% {
            transform: none;
        }
        60% {
            transform: translateY(16px);
        }
    }

    @keyframes left_finger {
        0% {
            transform: scaleX(0);
        }
        10% {
            transform: scaleX(1) rotate(6deg);
        }
        15% {
            transform: scaleX(1) rotate(0deg);
        }
        20% {
            transform: scaleX(1) rotate(8deg);
        }
        25% {
            transform: scaleX(1) rotate(0deg);
        }
        30% {
            transform: scaleX(1) rotate(7deg);
        }
        35% {
            transform: scaleX(1) rotate(0deg);
        }
        40% {
            transform: scaleX(1) rotate(5deg);
        }
        45% {
            transform: scaleX(1) rotate(0deg);
        }
        50% {
            transform: scaleX(1) rotate(6deg);
        }
        58% {
            transform: scaleX(0);
        }
    }

    @keyframes head {
        0% {
            transform: rotate(-3deg);
        }
        10% {
            transform: translatex(10px) rotate(7deg);
        }
        50% {
            transform: translatex(0px) rotate(0deg);
        }
        56% {
            transform: rotate(-3deg);
        }
    }

    @keyframes object-1 {
        0% {
            transform: translate(0, 0) rotate(0deg);
        }
        10% {
            transform: translate(0px, -70px) rotate(80deg);
        }

        57% {
            transform: translate(0px, -70px) rotate(440deg);
        }
        63% {
            transform: translate(0, 0) rotate(360deg);
        }
        100% {
            transform: translate(0, 0) rotate(360deg);
        }
    }

    @keyframes object-2 {
        0% {
            transform: translate(0, 0) rotate(0deg);
        }
        10% {
            transform: translate(0px, -70px) rotate(45deg);
        }

        58% {
            transform: translate(0px, -70px) rotate(-315deg);
        }
        63% {
            transform: translate(0, 0) rotate(-360deg);
        }
        100% {
            transform: translate(0, 0) rotate(-360deg);
        }
    }

    @keyframes object-3 {
        0% {
            transform: translate(0, 0) rotate(0deg);
        }
        10% {
            transform: translate(20px, -140px) rotate(45deg);
        }

        58% {
            transform: translate(20px, -140px) rotate(405deg);
        }
        63% {
            transform: translate(0, 0) rotate(360deg);
        }
        100% {
            transform: translate(0, 0) rotate(360deg);
        }
    }
}
