@layer components {

    .footer{
        & > .page__wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 15px;
            padding-bottom: 15px;
            max-width: calc(var(--wrapper-header-max) + 2*var(--page-gap) );
        }
        @media (min-width: theme(--breakpoint-md) ) {
            & > .page__wrapper{
                flex-direction: row;
            }
        }
    }

    .footer--front-page{
        background-color: white;
        box-shadow: var(--shadow-footer-front);
        margin-left: auto;
        margin-right: auto;
        & .footer__scroll-top{
            display: none;
        }
        @media (min-width: 1530px ) {
            --extra-gap: 30px;
            border-top-left-radius: calc( 2*var(--border-rad-md) );
            border-top-right-radius: calc( 2*var(--border-rad-md) );
            padding-left: var(--extra-gap);
            padding-right: var(--extra-gap);
            max-width: 1530px; /* $wrapper-header-max + 2*$page-gap + 2*$extra-gap; */
        }
    }

    .footer__copy{
        width: 100%;
        max-width: 250px;
        font-size: 13px;
        color: var(--color-grey3);
        text-align: center;
        @media (min-width: theme(--breakpoint-md) ) {
            margin-left: auto;
            text-align: right;
        }
        @media (min-width: theme(--breakpoint-lg) ) {

        }
    }

    .footer__info{
        margin-bottom: 15px;
        & .info--phone{
            font-size: 20px;
        }
        @media (min-width: theme(--breakpoint-md) ) {
            margin-bottom: 4px;
            margin-top: 4px;
            margin-right: 20px;
        }
        @media (min-width: theme(--breakpoint-lg) ) {

        }
    }

    .footer__wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: theme(--breakpoint-md) ) {
            justify-content: space-between;
        }
    }

    .footer__scroll-top{
        display: none;
        margin-right: 30px;
        & > .btn{
            & .icon__wrapper{
                border-radius: 50%;
                border: 1px solid;
                border-color: currentColor;
            }
        }
        @media (min-width: theme(--breakpoint-md) ) {
            display: block;
        }
    }
}
