@layer components {

    .header{
        background: white;
        box-shadow: var(--shadow-header);
        position: relative;
        z-index: var(--z-index-header);
        margin-bottom: var(--header-mb);
        & > .page__wrapper{
            display: flex;
            justify-content: space-between;
            padding-top: 12px;
            padding-bottom: 12px;
            max-width: calc( var(--wrapper-header-max) + 2*var(--page-gap) );
        }
    }

    .header--menu-open{
        height: 100vh;
        & > .page__wrapper{
            box-shadow: var(--shadow-header);
            position: relative;
        }
        & .header__menu{
            display: block;
            height: calc(100vh - 100%);
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            padding-left: var(--page-gap);
            padding-right: var(--page-gap);
            padding-top: 20px;
        }
        & .nav--header{
            margin-top: 0;
            margin-bottom: 30px;
        }
        & .header__menu-toggle{
            & .icon__vue--menuopen{
                display: none;
            }
            & .icon__vue--menuclose{
                display: block;
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            height: auto;
            & > .page__wrapper{
                box-shadow: none;
            }
            & .header__menu{
                display: flex;
                height: auto;
                position: static;
                top: 0;
                width: auto;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
            }
            & .nav--header{
                margin-top: -12px;
                margin-bottom: -12px;
            }
        }
    }

    .header__progress{
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
    }

    .header__menu{
        display: none;
        overflow: auto;
        @media (min-width: theme(--breakpoint-lg) ) {
            overflow: initial;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
        }
    }

    .header__menu-toggle{
        color: var(--color-primary);
        display: flex;
        align-items: center;
        & .icon__vue{
            width: 44px;
            height: 44px;
        }
        & .icon__vue--menuopen{

        }
        & .icon__vue--menuclose{
            display: none
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            display: none;
        }
    }

    .header__logo{
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-right: 55px;
        white-space: nowrap;
        color: black;
        text-decoration: none;
        flex-shrink: 0;
        &:hover{
            color: black;
            text-decoration: none;
        }
        & .icon__wrapper{
            margin-right: 16px;
        }
        @media (min-width: theme(--breakpoint-sm) ) {
            font-size: 24px;
        }
    }

    .header__right{
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        & > *{
            margin-bottom: 15px;
        }
        & .user{
            display: flex;
            flex-direction: column;
        }
        & .user__balance{
            margin-right: 40px;
            order: 1;
            margin-bottom: 5px;
        }
        & .user__name{
            margin-right: 12px;
            order: 2;
        }
        & .user__pic{
            margin-bottom: 10px;
        }
        & .control--dropdown{
            & > .control__activator{
                pointer-events: none;
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            flex-direction: row;
            align-items: center;
            & > *{
                margin-bottom: 0;
                &:not(:last-child){
                    margin-right: 15px;
                }
            }
            & .user{
                flex-direction: row;
                align-items: center;
            }
            & .user__balance{
                display: none;
                margin-bottom: 0;
                order: 0;
            }
            & .user__name{
                display: none;
                order: 0;
            }
            & .user__pic{
                margin-bottom: 0;
            }
            & .control--dropdown{
                & > .control__activator{
                    pointer-events: all;
                }
            }
        }
        @media (min-width: theme(--breakpoint-xl) ) {
            & .user__balance{
                display: block;
            }
            & .user__name{
                display: block;
            }
        }
    }

}
