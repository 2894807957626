@layer components {

    .messenger{
        width: 100%;
        background: white;
        border-radius: var(--border-rad-lg);
        margin-bottom: 10px;
    }

    .messenger__topic{

    }

    .messenger__input{

    }

    .messenger__messages-wrapper{

    }

    .messenger__messages{

    }

    .messenger--topics{
        background-color: transparent;
        & .message{
            background: white;
            border: 1px solid;
            border-color: var(--color-light3);
            border-radius: var(--border-rad-lg);
            margin-bottom: 10px;
        }
        & .message--closed{
            background-color: color-mix(in srgb, var(--color-light2) 40%, transparent 60%);
            & .message__body > .wrapper,
            & .message__footer{
                opacity: 0.5;
            }
        }
        & .message--new{
            background-color: color-mix(in srgb, var(--color-orange) 8%, transparent 92%);
            & .message__icon{
                color: var(--color-orange);
                animation: shake-bottom 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
            }
        }
        & .badge{
            margin-right: 8px;
        }
        & .message__body{
            flex-grow: 1;
            display: flex;
            padding: var(--message-py) var(--message-px) calc(var(--message-py)/2) var(--message-px);
            & > .wrapper{
                flex-grow: 1;
            }
        }
        & .message__footer{
            padding: calc(var(--message-py)/2) var(--message-px);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid;
            border-color: var(--color-light3);

        }
        & .wrapper--message-type{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 8px;
        }
        & .message__actions{
            margin-top: calc( -1*var(--message-py) );
            margin-right: calc( -1*var(--message-px) );
        }
        & .message__icon{
            margin-right: 8px;
        }
        & .message__type{
            margin-right: 8px;
            white-space: nowrap;
            display: inline-block;
            color: var(--color-grey1);
            font-size: 13px;
            padding: 2px 8px;
            background-color: var(--color-grey7);
            border: 1px solid;
            border-color: var(--color-grey6);
            border-radius: var(--border-rad-lg);
        }
        & .message__project{
            margin-bottom: 4px;
        }
        & .message__title{
            margin-bottom: 4px;
        }
        & .message__author{
            margin-right: 8px;
            font-size: 14px;
            font-weight: var(--fw-medium);
            white-space: nowrap;
            display: flex;
            align-items: center;
            & .user__pic{
                margin-right: 10px;
                width: 30px;
                height: 30px;
                cursor: help;
            }
        }
    }

    .messenger--dialog{
        border: 1px solid;
        border-color: var(--color-light3);
        & .messenger__topic{
            border-bottom: 1px solid;
            border-color: var(--color-light3);
            & .message__body{
                flex-grow: 1;
                display: flex;
                padding: var(--message-px) var(--message-px) calc(var(--message-px)/2);
                & > .wrapper{
                    flex-grow: 1;
                }
            }
            & .message__header{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                border-bottom: 1px solid;
                border-color: var(--color-light3);
                padding: calc(var(--message-px)/2) var(--message-px);
                & > .wrapper{
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                }
            }
            & .message__date{
                margin-top: 4px;
                margin-bottom: 4px;
            }
            & .message__type{
                margin-right: 8px;
            }
            & .message__title{
                margin-top: 8px;
            }
            & .message__project{
                margin-top: 8px;
            }
        }
        & .messenger__messages-wrapper{
            width: 100%;
            max-height: 600px;
            margin-top: 20px;
            margin-bottom: 20px;
            overflow-y: auto;
        }
        & .messenger__messages{
            width: 100%;
            list-style: none;
            margin-top: 0;
            margin-bottom: 0;
            padding: var(--message-px) var(--message-px);
            display: flex;
            flex-direction: column;
        }
        & .message{
            margin-bottom: 12px;
            border: 1px solid;
            border-color: var(--color-light3);
            background-color: color-mix(in srgb, var(--color-light2) 40%, transparent 60%);
            border-radius: var(--border-rad-lg);
            max-width: 90%;
            margin-right: auto;
            & .message__header{
                display: flex;
                align-items: center;
                border-bottom: 1px solid;
                border-color: var(--color-light3);
                padding: 6px 12px;
            }
            & .message__body{
                padding: 6px 12px;
            }
            & .message__footer{
                padding: 6px 12px;
            }
            & .message__date{
                text-align: right;
            }
            & .message__author{
                font-weight: var(--fw-medium);
                margin-right: 8px;
                flex-grow: 1;
                display: flex;
                align-items: center;
                & .user__pic{
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    cursor: help;
                }
            }
            &.message--self{
                background-color: var(--color-zanah);
                margin-right: 0;
                margin-left: auto;
            }
            &.message--service{
                margin: 12px auto;
                background-color: transparent;
                border: none;
                font-size: 13px;
                color: var(--color-grey2);
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                & .message__header{
                    padding: 0;
                    border: none;
                    & .message__author{
                        font-weight: var(--fw-normal);
                        flex-grow: 0;
                    }
                }
                & .message__body{
                    padding: 0;
                    margin-right: 8px;
                }
                & .message__footer{
                    padding: 0;
                }
                & .user__pic{
                    display: none;
                }
            }
        }
        & .messenger__input{
            border-top: 1px solid;
            border-color: var(--color-light2);
            padding: var(--message-px) var(--message-px);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            & .quill-editor{
                width: 100%;
            }
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            & .messenger__topic{
                & .message__type,
                & .message__title,
                & .message__project{
                    font-size: 18px;
                }
            }
        }
    }

    @keyframes shake-bottom {
        0%,
        100% {
            transform: rotate(0deg);
            transform-origin: 50% 100%;
        }
        10% {
            transform: rotate(2deg);
        }
        20%,
        40%,
        60% {
            transform: rotate(-4deg);
        }
        30%,
        50%,
        70% {
            transform: rotate(4deg);
        }
        80% {
            transform: rotate(-2deg);
        }
        90% {
            transform: rotate(2deg);
        }
    }
}
