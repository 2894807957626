@layer components {

    .constructor{
        min-height: 70vh;
        @media (min-width: theme(--breakpoint-lg) ) {
            display: flex;
        }
    }

    .constructor__content{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
        & > .control--collapse{
            padding-bottom: 40px;
            border-bottom: 2px solid;
            border-color: var(--color-light3);
            margin-bottom: 40px;
            & > .control__content{
                padding-top: 20px;
            }
        }
        & .hr{
            border-top: 2px solid;
            border-color: var(--color-light3);
        }
        @media (min-width: theme(--breakpoint-lg) ) {
            margin-right: 30px;
            margin-bottom: 0;
        }
    }

    .constructor__aside{
        width: 100%;
        @media (min-width: theme(--breakpoint-lg) ) {
            flex-shrink: 0;
            max-width: 250px;
            position: relative;
            & > .wrapper{
                position: sticky;
                top: 60px
            }
        }
    }

    .constructor__footer{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: auto;
        border-top: 1px solid;
        border-color: var(--color-light4);
        padding-top: 20px;
        & .btn{
            margin-top: 4px;
            margin-bottom: 4px;
            &:not(:last-of-type){
                margin-right: 16px;
            }
        }
    }

    .constructor__rule{
        width:100%;
        margin-right: auto;
        margin-left: auto;
    }
}
