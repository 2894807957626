@import 'tailwindcss/base';
    @import './base/mixins';
    @import './base/safelist';
    @import './base/common';
    @import './base/fonts';
    @import './base/headers';
@import 'tailwindcss/components';
    @import './components/page.css';
    @import './components/header.css';
    @import './components/main.css';
    @import './components/footer.css';
    @import './components/btn.css';
    @import './components/control.css';
    @import './components/badge.css';
    @import './components/form.css';
    @import './components/icon.css';
    @import './components/link.css';
    @import './components/menu.css';
    @import './components/nav.css';
    @import './components/text.css';
    @import './components/user.css';
    @import './components/wrapper.css';
    @import './components/info.css';
    @import './components/section.css';
    @import './components/list.css';
    @import './components/modal.css';
    @import './components/banner.css';
    @import './components/blog.css';
    @import './components/card.css';
    @import './components/job.css';
    @import './components/table.css';
    @import './components/preloader.css';
    @import './components/pagination.css';
    @import './components/constructor.css';
    @import './components/hr.css';
    @import './components/note.css';
    @import './components/scroll.css';
    @import './components/project.css';
    @import './components/messenger.css';
    @import './components/message.css';
    @import './components/alert.css';
    @import './components/tooltip.css';
    @import './components/snippet.css';
    @import './components/output.css';

    @import './legacy.css';

    @import './vendors/vue_toastification.css';
    @import './vendors/vue_datepicker.css';
    @import './vendors/multiselect.css';
    @import './vendors/vue_tippy.css';
    @import './vendors/quill_snow.css';
    @import './vendors/emoji_fonts.css';
    @import './vendors/shepherd.css';
    @import './vendors/hljs.css';

@import 'tailwindcss/utilities';

:root{
    --breakpoint-xs: 0px;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;

    --font-sans: Roboto, Arial, Helvetica, 'Noto Color Emoji', sans-serif;

    /*Variables*/
    --wrapper-header-max: 1440px;
    --wrapper-max: 1440px;
    --page-gap: 15px;
    --scrollbar-width:  17px;
    --anim-dur:  0.4s;
    --border-rad-sm: 2px;
    --border-rad-md: 3px;
    --border-rad-lg: 4px;
    --header-min-height: 74px;

    --control-sm-height: 32px;
    --control-xm-height: 25px;
    --control-height: 40px;

    --control-padding-y: 10px;
    --control-padding-x: 12px;
    --control-sm-padding-y: 6px;
    --control-sm-padding-x: 8px;

    --userpic-size: 48px;
    --main-py: 30px;
    --header-mb: 4px;
    --dropdown-px: 20px;
    --dropdown-py: 9px;
    --table-elem-first-col-w: 54px;
    --range-thumb-w: 24px;
    --btn-loading-border-w: 3px;

    --z-index-menu: 5;
    --z-index-header: 6;
    --z-index-overlap: 8;
    --z-index-card-task-footer: 11;
    --z-index-dropdown: 12;
    --z-index-tooltip: 13;
    --z-index-banner: 14;
    --z-index-fullscreen: 15;
    --z-index-modal: 16;

    /*Colors*/
    --color-primary:            #75B94E;
    --color-primary2:           #4A8736;
    --color-primary-hover:      #91C771;
    --color-grey1:              #343332;
    --color-grey2:              #61605F;
    --color-grey3:              #8E8D8D;
    --color-grey4:              #BBBBBB;
    --color-grey6:              #DEE1E3;
    --color-grey7:              #EDEEEF;
    --color-light1:             #F8F9F8;
    --color-light2:             #EBEEE9;
    --color-light3:             #DCE2DA;
    --color-light4:             #CDD5C9;
    --color-orange:             #E6A23C;
    --color-red:                #F56C6C;
    --color-orange-dark:        #BE8F19;
    --color-main-green:         #6EB74A;
    --color-yellow:             #FFE600;
    --color-blue:               #1181FD;
    --color-info:               #17A2B8;
    --color-zanah:			    #E3F1DC;
    --color-sprout:		        #B5D4A2;
    --color-quill-gray:	        #D5D4D3;
    --color-eucalyptus:         #28A745;

    --shadow-small: 0px 2px 4px rgba(29, 48, 19, 0.08);
    --shadow-medium: 0px 2px 7px rgba(29, 48, 19, 0.3);
    --shadow-big: 0px 16px 48px rgba(29, 48, 19, 0.16);
    --shadow-initial: 0px 0px 0px rgba(0, 0, 0, 0);
    --shadow-btn-hover: 0px 4px 8px rgba(29, 48, 19, 0.16);
    --shadow-footer-front: 0px 4px 8px rgba(29, 48, 19, 0.08);
    --shadow-header: 0px 4px 8px rgba(0, 0, 0, 0.08);
    --shadow-modal-header: 0px 2px 4px rgba(0, 0, 0, 0.08);
    --shadow-modal-footer: 0px -4px 8px rgba(0, 0, 0, 0.08);
    --shadow-tippy: 0px 0px 11px rgba(0, 0, 0, 0.1), 0px 16px 48px rgba(29, 48, 19, 0.16);
    --shadow-checkbox: 0px 4px 8px rgba(117, 185, 78, 0.3);

    /*Fonts*/
    --fw-thin: 100;
    --fw-extra-light: 200;
    --fw-light: 300;
    --fw-normal: 400;
    --fw-medium: 500;
    --fw-semi-bold: 600;
    --fw-bold: 700;
    --fw-extra-bold: 800;
    --fw-black: 900;

    --font-sber: SBSansText;

    --modal-px-sm: 15px;
    --modal-py-sm: 16px;
    --modal-px-md: 48px;
    --modal-py-md: 32px;
    --modal-close-width: 44px;

    --message-px: 15px;
    --message-py: 10px;
    --cursor-remove-pic: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAATlBMVEUAAAAAAQgAAQsAAAYAAAYAAQgAAAcAAAgAAQkAAAbg4OIAAAcAAg4AAQgAAQoBAhH////09Pfi4uTg4OLX19n4+fvw8PPp6evDw8u0tb6VtFSaAAAAEHRSTlMAvTPn9cPTy7UW+ddMKZ8acD6RgwAAAIdJREFUKM+tzssKwkAMheGcjHOprZrWenv/F3UYI1M4dCH4QxbhyyISVHYK0D2xL7F0IulE4sTSiaUTy82Jxe5OLPZwYrEFLLMZaizz4WWY+LcKmmxJIoN6LhWkPK1MMvixCyqIjramrfTKallUT9pmK5fx/NlDRCuCHrpa6/iDADkCMQPy196sxwd9u0+gVAAAAABJRU5ErkJggg==");
    --cursor-add-pic: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAV1BMVEUAAAAAAQgAAAYAAg8AAQgAAAgAAQjg4OIAAAYAAAcAAQgAAQkAAAYAAAcAAg4AAQgAAQgAAQgAAQoBAhH////h4eP09PfX19n4+fvw8PPp6evDw8u0tb4QXJeDAAAAFHRSTlMAvec2Mcu/+vXTxbUW10wp7+6fGiQFE1IAAACaSURBVCjPldDJDsMgEAPQmQRIs3YzSbf//86iMIiD20MsceFJljXStPInjRqxoBBLIZZKLEYslVgqsTyMWPA0YsHLiAVRWVZAU1jW0we68LYEbY/Yi0zOYpJAxjfGRab8oz73agJpZ2x9kdB1oXaOG7w4d3bpecBXuc83l8sHvQKqQWlQxJ7ugGjwF2DwexuhLeAMMYbfYhf8Amu1Cl/XyqLKAAAAAElFTkSuQmCC");
}

