@define-mixin scrollThin {
	/*new syntax for firefox*/
	scrollbar-width: thin;
	scrollbar-gutter: auto;/*stable*/

	/*old syntax for webkit browsers*/
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		scrollbar-gutter: auto;/*stable*/
	}
	&::-webkit-scrollbar-track {
		background-color: color-mix(in oklch, black 95%, transparent);
		border-radius: 8px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey4);
		border-radius: 8px;
	}
}

@define-mixin ieOnly {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
		@mixin-content;
	}
}

@define-mixin safariOnly {
	@media not all and (min-resolution:.001dpcm){
		@mixin-content;
	}
}

@define-mixin controlRangeThumb $bg-color: var(--color-primary), $border-color: white {
	box-shadow: none;
	border: 1px solid $border-color;
	height: var(--range-thumb-w);
	width: var(--range-thumb-w);
	border-radius: 50%;
	background: $bg-color;
	cursor: pointer;
}

@define-mixin controlRangeTrack $bg-color: var(--color-primary) {
	box-shadow: none;
	background: $bg-color;
	border-radius: var(--range-thumb-w);
	border: none;
}
