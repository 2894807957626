@layer components {

    .link{
        cursor: pointer;
    }

    .link__text{

    }

    .link--dotted{
        & .link__text{
            display: inline;
            text-decoration: underline;
            text-decoration-style: dotted;
            text-underline-offset: 4px;
        }
    }

    .link--primary{
        color: var(--color-primary);
        &:hover{
            color: var(--color-primary-hover);
        }
    }

    .link--blue{
        color: var(--color-blue);
        &:hover{
            color: color-mix(in srgb, var(--color-blue), white 15%);;
        }
    }

    .link--disabled{
        pointer-events: none;
        color: var(--color-light3);
        &:hover{
            color: var(--color-light3);
        }
    }
}
