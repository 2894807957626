@layer components {

    .page{
        display: flex; /*fix бага ie11 с min-height:100%*/
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        position: relative;
        z-index: 1;
        background-color: var(--color-light1);
    }

    .page__wrapper{
        width: 100%;
        max-width: calc(var(--wrapper-max) + 2*var(--page-gap));
        padding-left: var(--page-gap);
        padding-right: var(--page-gap);
        margin-left: auto;
        margin-right: auto;
    }

    .page__main{
        width: 100%;
        flex-grow: 1;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
    }


    .page__header{
        width: 100%;
    }

    .page__footer{
        width: 100%;
        & > .page__wrapper{

        }
    }

    .page__body{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 100vh;
        font-size: 15px;
    }

    .page__title{
        color: black;
        font-size: 24px;
        font-weight: var(--fw-normal);
        @media (min-width: theme(--breakpoint-md) ) {
            font-size: 32px;
        }
    }

    .page--noscroll{
        overflow: hidden;
        @mixin safariOnly {
            position: fixed;
        }
    }
}

