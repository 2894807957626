@layer components {

    .pagination{
        display:flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pagination__item {
        flex-shrink: 0;
        &:first-child {
            .pagination__link {
                margin-left:0;
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }
        }
        &:last-child {
            .pagination__link {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }

        &.pagination__item--active{
            .pagination__link {
                z-index:1;
                color: white !important;
                background-color: var(--color-primary);
                border-color: var(--color-primary);
            }
        }

        &.pagination__item--disabled{
            .pagination__link {
                color: var(--color-grey2) !important;
                pointer-events:none;
                cursor:auto;
                background-color: var(--color-light2);
                border-color: var(--color-grey6);
                cursor: not-allowed;
            }
        }
    }

    .pagination__link {
        position:relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--control-sm-height);
        min-width: var(--control-sm-height);
        padding: 0.25rem;
        margin-left:-1px;
        line-height:1.25;
        color: var(--color-primary);
        background-color: white;
        border:1px solid  var(--color-grey6);
        user-select: none;
        cursor: pointer;
        white-space: nowrap;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 1px;
        text-overflow: '';
        &:hover {
            z-index:2;
            color: var(--color-primary2);
            text-decoration:none;
            background-color: var(--color-grey7);
            border-color: var(--color-grey6);
        }

        &:focus {
            z-index:2;
            outline:0;
            box-shadow:0 0 0 .2rem color-mix(in srgb, var(--color-primary) 25%, transparent 75%);
        }
        &::-ms-expand {
            display: none;
        }
        & .icon__vue{
            height: 20px;
            width: 20px;
        }
        & option{
            background-color: white;
        }
    }

    .pagination__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 15px;
        & .control--select{
            min-width: 80px;
        }
    }
}
